import React from 'react'
import { Link } from 'react-router-dom'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import profile from '../assets/default_user.png'
import parse from 'html-react-parser';
import TextTranslator from "../util/TextTranslator";

export default function ResultCard(props) {
    const { result } = props

    return (
        <Col style={{ cursor: 'pointer', textDecoration: 'none' }}>
            <Link style={{ textDecoration: 'none' }} to={`/result/${result.id}`}>
                <Row
                    style={{
                        fontFamily: 'Open Sans',
                        boxShadow: '1px 1px 6px #00000014',
                        borderRadius: 10,
                        fontSize: '.85em',
                        color: 'black',
                        paddingTop : 15,
                        paddingBottom : 15
                    }}
                >
                    <Col md={3}>
                        <div
                            style={{
                                textAlign: 'center',
                                paddingBottom: 10,
                            }}
                        >
                            <img
                                src={result?.profile_picture_url ? result.profile_picture_url : profile}
                                style={{
                                    maxWidth: '100%',
                                    objectFit: 'contain',
                                    borderRadius : 5
                                }}
                                alt={result?.full_name}
                            />
                        </div>
                    </Col>
                    <Col className="mt-3">
                        <div
                            style={{
                                fontFamily: 'Roboto',
                                fontSize: 15,
                            }}
                        >
                            {result?.full_name}
                        </div>
                        

                        <div className="d-flex">
                            {
                                (result.profession === "Both" || result.profession === "both") ?
                                    <>
                                        <div
                                            className="px-2 mr-1"
                                            style={{
                                                color: '#224AD7',
                                                borderColor: '#224AD7',
                                                borderRadius: '20px',
                                                border: 'thin solid',
                                            }}
                                        >
                                            Juru Bahasa
                                        </div>
                                        <div
                                            className="px-2 mr-1"
                                            style={{
                                                color: '#224AD7',
                                                borderColor: '#224AD7',
                                                borderRadius: '20px',
                                                border: 'thin solid',
                                            }}
                                        >
                                            Penerjemah
                                        </div>
                                    </>
                                    :
                                    <div
                                        className="px-2 mr-1"
                                        style={{
                                            color: '#224AD7',
                                            borderColor: '#224AD7',
                                            borderRadius: '20px',
                                            border: 'thin solid',
                                        }}
                                    >
                                        {TextTranslator.getProfesi(result.profession)}
                                    </div>
                            }
                        </div>
                        {
                            result.resume ?
                                <div className="mt-2">{parse(result.resume)}</div>
                                :
                                ""

                        }

                    </Col>
                </Row>
            </Link>
        </Col>
    )
}
