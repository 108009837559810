import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Swal from 'sweetalert2'
import moment from 'moment'
import { Accordion, Card, Form } from 'react-bootstrap'
import {
    FaPlus,
    FaDownload,
    FaRegistered,
    SiGoogleclassroom,
    ImNewTab,
    ImEnter,
    FaWhatsapp,
    AiOutlineFileText,
    BsDownload,
} from 'react-icons/all'
import Modal from 'react-bootstrap/Modal'
import SimpleLineIcon from 'react-simple-line-icons'
import { AiOutlineClose } from 'react-icons/all'
import IconButton from '@material-ui/core/IconButton'
import { Link, useHistory } from 'react-router-dom'

import Class from '../models/Class'
import Palette from '../Palette'
import { isMobile } from 'react-device-detect'
import Transaction from '../models/Transaction'
import Member from '../models/Member'
import Voucher from '../models/Voucher'
import textFormatter from "../util/TextFormatter";
import Alert from "react-bootstrap/Alert";

const hasDebt = JSON.parse(localStorage.getItem('user'))?.has_debt
var momentTz = require('moment-timezone')
const memberData = JSON.parse(localStorage.user ?? '[]')

function thousandSeparator(x) {
    if (x == null) return '-'
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
}

function determinePrice(x) {
    if (x === 0) return 'FREE'
    if (!x) return 'Not available'
    return 'Rp' + thousandSeparator(x)
}

export default function ActivityDetailPage(props) {
    const history = useHistory()

    const getActivityDetail = async () => {
        let classModel = new Class()

        try {
            let classDetail = await classModel.getClassDetail(props.match.params.id)

            setClassDetail(classDetail)
            console.log(classDetail)
            getMemberDetail(classDetail)
        } catch (e) {
            console.log('error: ' + e)
        }
    }
    const [isValidMembership, setIsValidMembership] = useState(true)
    const getMemberDetail = async (classDetail) => {
        let memberModel = new Member()

        try {
            let memberDetail = await memberModel.getMemberData()
                if(memberDetail?.member?.has_debt){
                    setIsValidMembership(false)
                }else{
                    setIsValidMembership(true)
                }
            
            setMemberDetail(memberDetail)
            console.log(memberDetail)

            if (!memberDetail) setFinalPrice(classDetail.normal_member_price)
            else if (!memberDetail.paid) setFinalPrice(classDetail.normal_member_price)
            else if (memberDetail?.member.role === "MUDA") setFinalPrice(classDetail.muda_price)
            else if (memberDetail?.member.role) setFinalPrice(classDetail.penuh_price)


        } catch (e) {
            console.log('error: ' + e)
        }
    }


    useEffect(() => {
        getActivityDetail()
    }, [])

    const checkVoucher = async (code) => {
        try {
            setVoucher(null)

            let voucherx = await Voucher.checkByCode(code)

            if (voucherx.minimum_usage > finalPrice)
                return Swal.fire('Penggunaan Minimal', `Penggunaan minimal voucer adalah ${voucherx.minimum_usage}`, 'error')

            setVoucher(voucherx)
            if (voucherx) setVoucherPrice(calculateVoucherDiscount(finalPrice, voucherx))

        } catch (error) {
            console.log(error)
            if (error.code === 'VOUCHER_NOT_FOUND') Swal.fire('Tidak Ditemukan', "Voucer tidak ditemukan", 'error')
            else Swal.fire(error.code, error.error_message, 'error')
        }

    }

    const register = async (id) => {
        let classModel = new Class()

        console.log(id)
        try {
            let result = await classModel.registerClass(id, voucherCode)

            if (result.transaction) {
                window.location.href = result.transaction.snap_payment_url
                return
            }

            setRegisterModalShown(false)

            Swal.fire({
                title:
                    result.status === 'ACCEPTED'
                        ? 'Anda telah berhasil terdaftar!'
                        : 'Anda berada di daftar antrian. ',
                icon: result.status === 'ACCEPTED' ? 'success' : 'info',
                confirmButtonText: 'OKE',
            })
            getActivityDetail()
        } catch (e) {
            if (e.code === 'REGISTRATION_IS_CLOSED') {
                Swal.fire({
                    title: 'Pendaftaran telah ditutup',
                    icon: 'info',
                    confirmButtonText: 'OKE',
                })
            }

            Swal.fire({
                title: 'An error occured',
                icon: 'error',
                confirmButtonText: 'OKE',
            })

            console.log('error register ' + e)
        }
    }

    const calculateVoucherDiscount = (originalAmount, voucher) => {

        let isPercentageDiscount = !!voucher.percentage
        let discountAmount = 0

        if (isPercentageDiscount) {
            discountAmount = Math.round((originalAmount * (voucher.percentage / 100)))
        } else {
            discountAmount = voucher.amount
        }

        if (discountAmount > originalAmount) {
            discountAmount = originalAmount
        }

        let processedAmount = originalAmount - discountAmount

        setVoucherPrice(discountAmount)
        return {
            originalAmount: originalAmount,
            type: isPercentageDiscount ? "percentage" : "amount",
            total: processedAmount,
            discount: discountAmount
        }
    }

    const handleRegisterMember = () => {
        if (isLoggedIn) {
            setRegisterModalShown(true)
        } else {
            setNonMemberModalShown(true)
        }
    }

    useEffect(() => {
        getActivityDetail()
    }, [])


    const [classDetail, setClassDetail] = useState({})
    const [memberDetail, setMemberDetail] = useState({})
    const [voucherCode, setVoucherCode] = useState('')
    const [voucher, setVoucher] = useState(null)
    const [finalPrice, setFinalPrice] = useState(0)
    const [voucherPrice, setVoucherPrice] = useState(0)

    const [isRegisterModalShown, setRegisterModalShown] = useState(false)
    const [isNonMemberModalShown, setNonMemberModalShown] = useState(false)
    const [showDownloadMaterial, setShowDownloadMaterial] = useState(false)
    const [showDownloadRecording, setShowDownloadRecording] = useState(false)

    let isLoggedIn = !!localStorage.getItem('user')

    const isAllPriceSame = classDetail?.penuh_price === classDetail?.muda_price === classDetail?.normal_member_price === classDetail?.normal_price

    const classContent = (
        <>
            <Row style={{ paddingLeft: 25, marginTop: 30, marginLeft: 0 }}>
                <SimpleLineIcon name="user" style={{ marginRight: 18, color: Palette.PRIMARY }} />
                <div
                    style={{
                        fontSize: 14,
                        color: '#575757',
                        fontWeight: 'bold',
                        fontFamily: 'Signika',
                    }}
                >
                    Pembicara
                </div>
            </Row>
            <Row style={{ paddingLeft: 25, marginTop: 5, marginLeft: 0 }}>
                <SimpleLineIcon
                    name="tag"
                    style={{ marginRight: 18, color: Palette.PRIMARY, opacity: 0 }}
                />
                <div
                    style={{
                        fontSize: 14,
                        color: '#575757',
                        fontFamily: 'Signika',
                    }}
                >
                    {classDetail.speaker}
                </div>
            </Row>
            <Row
                style={{
                    backgroundColor: '#e9eaec',
                    height: 1,
                    width: '100%',
                    marginTop: 15,
                    marginBottom: 15,
                    marginLeft: 0,
                }}
            />{' '}
            <Row
                style={{
                    color: '#302a28',
                    fontFamily: 'Signika',
                    paddingLeft: 25,
                    lineHeight: 1.375,
                    marginLeft: 0,
                }}
            >
                <SimpleLineIcon name="tag" style={{ marginRight: 18, color: Palette.PRIMARY }} />
                <div
                    style={{
                        fontSize: 14,
                        color: '#575757',
                        fontWeight: 'bold',
                    }}
                >
                    Harga
                </div>
            </Row>
            <Row style={{ paddingLeft: 25, marginTop: 5, marginLeft: 0 }}>
                <SimpleLineIcon
                    name="tag"
                    style={{
                        marginRight: 18,
                        color: Palette.PRIMARY,
                        opacity: 0,
                    }}
                />
                <div
                    style={{
                        fontSize: 14,
                        color: '#575757',
                        fontFamily: 'Signika',
                    }}
                >
                    {
                        classDetail.penuh_price !== null && <>
                            {
                                `Anggota Penuh : ${determinePrice(classDetail.penuh_price)}`
                            }
                        </>
                    }
                    <br />
                    {
                        classDetail.muda_price !== null && <>
                            {
                                `Anggota Muda : ${determinePrice(classDetail.muda_price)}`
                            }
                        </>
                    }
                    <br />
                    {
                        (classDetail.normal_member_price !== null && classDetail.normal_member_price !== undefined) && <>
                            {
                                `Anggota Belum Bayar : ${determinePrice(classDetail.normal_member_price)}`
                            }
                        </>
                    }
                    <br />
                    {
                        (classDetail.normal_price !== null && classDetail.normal_price !== undefined) && <>
                            {
                                `Non Anggota : ${determinePrice(classDetail.normal_price)}`
                            }
                        </>
                    }

                </div>
            </Row>
            <br></br>
            <>
                {' '}
                <Row
                    style={{
                        color: '#302a28',
                        fontFamily: 'Signika',
                        paddingLeft: 25,
                        lineHeight: 1.375,
                        marginLeft: 0,
                    }}
                >
                    <SimpleLineIcon
                        name="tag"
                        style={{ marginRight: 18, color: Palette.PRIMARY }}
                    />
                    <div
                        style={{
                            fontSize: 14,
                            color: '#575757',
                            fontWeight: 'bold',
                        }}
                    >
                        Kuota
                    </div>
                </Row>
                <Row style={{ paddingLeft: 25, marginTop: 5, marginLeft: 0 }}>
                    <SimpleLineIcon
                        name="tag"
                        style={{
                            marginRight: 18,
                            color: Palette.PRIMARY,
                            opacity: 0,
                        }}
                    />
                    <div
                        style={{
                            fontSize: 14,
                            color: '#575757',
                            fontFamily: 'Signika',
                        }}
                    >
                        {classDetail.quota > 0 ? classDetail.quota : 0} peserta
                    </div>
                </Row>
            </>
            <Row
                style={{
                    backgroundColor: '#e9eaec',
                    height: 1,
                    width: '100%',
                    marginTop: 15,
                    marginBottom: 15,
                    marginLeft: 0,
                }}
            />
            <Row
                style={{
                    color: '#302a28',
                    fontFamily: 'Signika',
                    paddingLeft: 25,
                    lineHeight: 1.375,
                    marginLeft: 0,
                }}
            >
                <SimpleLineIcon
                    name="calendar"
                    style={{ marginRight: 18, color: Palette.PRIMARY }}
                />
                <div style={{ fontSize: 14, color: '#575757', fontWeight: 'bold' }}>Tanggal</div>
            </Row>
            <Row
                style={{
                    paddingLeft: 25,
                    marginTop: 5,
                    marginLeft: 0,
                    display: 'flex',
                    flexDirection: 'row',
                }}
            >
                <SimpleLineIcon
                    name="tag"
                    style={{ marginRight: 18, color: Palette.PRIMARY, opacity: 0 }}
                />
                <div
                    style={{
                        fontSize: 14,
                        color: '#575757',
                        fontFamily: 'Signika',
                        flex: 1,
                    }}
                >
                    {moment(classDetail.start_time).isSame(classDetail.end_time, 'day') ? (
                        moment(classDetail.start_time).format('dddd, DD MMMM YYYY')
                    ) : (
                        <>
                            {moment(classDetail.start_time).format('dddd, DD MMMM YYYY')} --{' '}
                            {moment(classDetail.end_time).format('dddd, DD MMMM YYYY')}
                        </>
                    )}
                </div>
            </Row>
            <Row
                style={{
                    backgroundColor: '#e9eaec',
                    height: 1,
                    width: '100%',
                    marginTop: 15,
                    marginBottom: 15,
                    marginLeft: 0,
                }}
            />
            <Row
                style={{
                    color: '#302a28',
                    fontFamily: 'Signika',
                    paddingLeft: 25,
                    lineHeight: 1.375,
                    marginLeft: 0,
                }}
            >
                <SimpleLineIcon name="clock" style={{ marginRight: 18, color: Palette.PRIMARY }} />
                <div style={{ fontSize: 14, color: '#575757', fontWeight: 'bold' }}>Waktu</div>
            </Row>
            <Row style={{ paddingLeft: 25, marginTop: 5, marginLeft: 0 }}>
                <SimpleLineIcon
                    name="tag"
                    style={{
                        marginRight: 18,
                        color: Palette.PRIMARY,
                        opacity: 0,
                        marginLeft: 0,
                    }}
                />
                <div
                    style={{
                        fontSize: 14,
                        color: '#575757',
                        fontFamily: 'Signika',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    {classDetail.display_time ? (
                        <div
                            style={{
                                height: 110,
                                display: 'flex',
                                flexDirection: 'column',
                                overflow: 'scroll',
                            }}
                        >
                            {classDetail.display_time.map((obj, key) => {
                                return <div>{obj}</div>
                            })}
                        </div>
                    ) : (
                        <>
                            {momentTz(classDetail.start_time).tz('Asia/Jakarta').format('HH.mm')}{' '}
                            WIB--
                            {momentTz(classDetail.end_time).tz('Asia/Jakarta').format('HH.mm ')} WIB
                        </>
                    )}
                </div>
            </Row>
            <Row
                style={{
                    backgroundColor: '#e9eaec',
                    height: 1,
                    width: '100%',
                    marginTop: 15,
                    marginBottom: 15,
                    marginLeft: 0,
                }}
            />

        </>
    )

    const downloadModal = (content, showModal, setShowModal) => {
        return (
            <Modal show={showModal}>
                <Modal.Header closeButton onHide={() => setShowModal(false)}>
                    <div style={{ fontSize: '1.1em', textAlign: 'center', fontWeight: 'bold' }}>
                        Download Material
                    </div>
                </Modal.Header>
                {/*<Row className="mt-2 mb-4">*/}
                {/*    <Col className="ml-4">*/}
                {/*        <h4 className="my-2">Download Material</h4>*/}
                {/*    </Col>*/}
                {/*    <Col>*/}
                {/*        <IconButton*/}
                {/*            onClick={() => {*/}
                {/*                setShowModal(false)*/}
                {/*            }}*/}
                {/*            onMouseDown={(e) => e.preventDefault()}*/}
                {/*            className="position-absolute pr-4"*/}
                {/*            style={{right: 0}}*/}
                {/*        >*/}
                {/*            <AiOutlineClose color={Palette.PRIMARY}/>*/}
                {/*        </IconButton>*/}
                {/*    </Col>*/}
                {/*</Row>*/}
                <Modal.Body className="p-0 px-4 py-1 mb-4">
                    {content.map((c) => {
                        return (
                            <>
                                <Row>
                                    <Col style={{ marginTop: 14 }}>
                                        <a href={c.url} style={{ color: Palette.PRIMARY }}>
                                            {c.title}
                                            <BsDownload
                                                className="mx-1 position-absolute"
                                                style={{ right: 20, top: '5px' }}
                                            />
                                        </a>

                                        <hr className="my-2 mt-3"></hr>
                                    </Col>
                                </Row>
                            </>
                        )
                    })}
                </Modal.Body>
            </Modal>
        )
    }

    const recordingModal = (content, showModal, setShowModal) => {
        return (
            <Modal show={showModal}>
                <Row className="mt-2 mb-4">
                    <Col className="ml-4">
                        <h4 className="my-2">Watch Recording </h4>
                    </Col>
                    <IconButton
                        onClick={() => {
                            setShowModal(false)
                        }}
                        onMouseDown={(e) => e.preventDefault()}
                        className="position-absolute"
                        style={{ right: 0 }}
                    >
                        <AiOutlineClose color={Palette.PRIMARY} />
                    </IconButton>
                </Row>
                <Modal.Body className="p-0 px-4 py-1 mb-4">
                    {content.map((c) => {
                        return (
                            <>
                                <hr className="my-2"></hr>
                                <Row>
                                    <Col>
                                        <Link
                                            to={{
                                                pathname: '/member/rekaman',
                                                state: { url: c.url },
                                            }}
                                            className="w-100"
                                            style={{ color: Palette.PRIMARY }}
                                        >
                                            {c.title}
                                            <ImNewTab
                                                className="mx-1 position-absolute"
                                                style={{ right: 0, top: '5px' }}
                                                color={Palette.PRIMARY}
                                            />
                                        </Link>
                                    </Col>
                                </Row>
                            </>
                        )
                    })}
                </Modal.Body>
            </Modal>
        )
    }

    let showRecording = moment().isAfter(moment(classDetail.end_time))

    let classHasStarted =
        moment().isAfter(classDetail.start_time) && moment().isBefore(classDetail.end_time)

    let nonMemberModal = (<Modal show={isNonMemberModalShown} onHide={() => setNonMemberModalShown(false)}>
        <Modal.Header>
            <b>Kelas ini khusus anggota</b>
        </Modal.Header>
        <Modal.Body>
            Harap masuk atau daftar untuk melanjutkan
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={() => history.push('/login')} className='pt-1'>
                Daftar/Masuk
            </Button>
        </Modal.Footer>
    </Modal>)

    return (
        <Container>
            <title>{classDetail.name ? classDetail.name : 'Kelas Daring Narabahasa'}</title>
            <meta
                name="description"
                content={`Kelas Daring Narabahasa ${classDetail.name ? classDetail.name : ''}`}
            />
            {nonMemberModal}
            <Modal show={isRegisterModalShown} onHide={() => setRegisterModalShown(false)}>
                <Modal.Body>
                    <Row>
                        <Col className="text-center">
                            <h4>{classDetail.name}</h4>
                        </Col>
                    </Row>
                    {classContent}
                    <Row
                        style={{
                            color: '#302a28',
                            fontFamily: 'Signika',
                            paddingLeft: 25,
                            lineHeight: 1.375,
                            marginLeft: 0,
                            display: "flex",
                            flexDirection: "column"
                        }}
                    >
                        <div style={{
                            display: "flex",
                            flexDirection: "row"
                        }}>
                            <SimpleLineIcon name="tag" style={{ marginRight: 18, color: Palette.PRIMARY }} />
                            <div style={{ fontSize: 14, color: '#575757', fontWeight: 'bold' }}>Voucher</div>
                        </div>

                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "flex-start",
                            paddingLeft: 30
                        }}>
                            <Form.Control
                                value={voucherCode}
                                onChange={(e) => {
                                    setVoucherCode(e.target.value)
                                }}
                                type="text"
                                name="search"
                                placeholder="Masukkan Kode Voucer"
                                className="mb-4 mr-1"
                            />
                            <Button
                                style={{
                                    height: 35,
                                    width: 100,
                                    fontFamily: 'Signika',
                                    fontWeight: '600',
                                    backgroundColor: Palette.PRIMARY,
                                    borderColor: Palette.PRIMARY,
                                }}
                                className="mr-3"
                                onClick={() => {
                                    checkVoucher(voucherCode)
                                }}
                            >
                                Check
                            </Button>{' '}
                        </div>
                    </Row>
                    <>
                        {' '}
                        <Row
                            style={{
                                color: '#302a28',
                                fontFamily: 'Signika',
                                paddingLeft: 25,
                                lineHeight: 1.375,
                                marginLeft: 0,
                            }}
                        >
                            <SimpleLineIcon
                                name="tag"
                                style={{ marginRight: 18, color: Palette.PRIMARY }}
                            />
                            <div
                                style={{
                                    fontSize: 14,
                                    color: '#575757',
                                    fontWeight: 'bold',
                                }}
                            >
                                Harga Total
                            </div>
                        </Row>
                        <Row style={{ paddingLeft: 25, marginTop: 5, marginLeft: 0 }}>
                            <SimpleLineIcon
                                name="tag"
                                style={{
                                    marginRight: 18,
                                    color: Palette.PRIMARY,
                                    opacity: 0,
                                }}
                            />
                            <div
                                style={{
                                    fontSize: 13,
                                    color: '#575757',
                                    fontFamily: 'Signika',
                                }}
                            >
                                {!finalPrice?.originalAmount ?
                                    'Rp ' + textFormatter.moneyFormatter(finalPrice)
                                    :
                                    <>
                                        <Row>

                                            <Col xs={6}>
                                                {'Original price'}
                                            </Col>
                                            <Col xs={6}>
                                                Rp {textFormatter.moneyFormatter(finalPrice?.originalAmount)}
                                            </Col>
                                        </Row>
                                        <Row xs={12}>

                                            <Col xs={6}>
                                                {'Discount'}
                                            </Col>
                                            <Col xs={6}>
                                                {finalPrice.type === 'amount' ?
                                                    '- Rp ' + textFormatter.moneyFormatter(finalPrice?.discount)
                                                    :
                                                    '- ' + voucher?.percentage + '%'
                                                }

                                            </Col>
                                        </Row>
                                        <Row xs={12}>

                                            <Col xs={6}>
                                                {'Total'}
                                            </Col>
                                            <Col xs={6}>
                                                <b>Rp {textFormatter.moneyFormatter(finalPrice?.total)}</b>
                                            </Col>
                                        </Row>

                                    </>

                                }

                            </div>

                        </Row>

                    </>
                    <Row
                        style={{
                            color: '#302a28',
                            fontFamily: 'Signika',
                            paddingLeft: 25,
                            lineHeight: 1.375,
                            marginLeft: 0,
                        }}>

                    </Row>
                    <Row className="my-3 py-3 text-center">
                        <Col
                            style={{
                                fontFamily: 'Signika',
                                fontWeight: '900',
                            }}
                        >
                            Konfirmasi pendaftaran untuk kelas ini?
                        </Col>
                    </Row>
                    <Row className="text-center">
                        <Col>
                            <Button
                                style={{
                                    height: 45,
                                    width: 140,
                                    fontFamily: 'Signika',
                                    fontWeight: '600',
                                    backgroundColor: Palette.PRIMARY,
                                    borderColor: Palette.PRIMARY,
                                }}
                                className="mr-3"
                                onClick={() => {
                                    register(classDetail.id)
                                }}
                            >
                                Konfirmasi
                            </Button>{' '}
                            <Button
                                style={{
                                    height: 45,
                                    width: 140,
                                    fontFamily: 'Signika',
                                    fontWeight: '600',
                                    backgroundColor: Palette.GREY,
                                    borderColor: Palette.GREY,
                                }}
                                onClick={() => setRegisterModalShown(false)}
                            >
                                Batalkan
                            </Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
            <Container>
                <Row
                    style={{
                        marginTop: 15,
                        marginBottom: 30,
                    }}
                >
                    <Col md={12}>
                        <span
                            style={{ color: '#007bff', cursor: 'pointer' }}
                            onClick={() => history.push('/kegiatan')}
                        >
                            {'<'} Kembali ke Kelas
                        </span>
                    </Col>
                </Row>

                <Row>
                    <Col md={12} lg={7}>
                        <img
                            src={
                                classDetail.class_image_url
                                    ? classDetail.class_image_url
                                    : '/image/no-img-placeholder.png'
                            }
                            style={{
                                objectFit: 'contain',
                                width: '100%',
                                backgroundColor: 'white',
                            }}
                            alt={classDetail.name}
                        />
                    </Col>

                    <Col lg={5}>
                        <Row
                            style={{
                                color: '#302a28',
                                fontFamily: 'Signika',
                                fontSize: 30,
                                lineHeight: 1.375,
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <Col>
                                {classDetail.name}
                            </Col>
                        </Row>

                        {!isLoggedIn && classDetail.availability !== 'OPEN_FOR_ALL' &&
                            <Row>
                                <Col>
                                    Khusus anggota
                                </Col>
                            </Row>
                        }

                        <Row style={{ marginLeft: 0, display: 'flex' }}>
                            {isValidMembership && classDetail.name && (
                                <>
                                    {
                                        classDetail.is_registered?.status === 'PENDING'
                                        &&
                                        <Alert
                                            style={{
                                                marginTop: 10
                                            }}
                                            variant={"info"}>
                                            Anda berada di daftar antrian. Kuota habis namun Anda akan dikabarkan
                                            apabila sudah tersedia
                                        </Alert>
                                    }

                                    <Button
                                        style={{
                                            fontFamily: 'Signika',
                                            fontWeight: '600',
                                            backgroundColor:
                                                classDetail.is_registered?.status === 'ACCEPTED'
                                                    ? Palette.PRIMARY
                                                    : classDetail.quota === 0
                                                        ? Palette.GREY
                                                        : Palette.PRIMARY,
                                            borderColor:
                                                classDetail.is_registered?.status === 'ACCEPTED'
                                                    ? Palette.PRIMARY
                                                    : classDetail.quota === 0
                                                        ? Palette.GREY
                                                        : Palette.PRIMARY,
                                            marginTop: 5,
                                        }}
                                        className="text-uppercase mr-2"
                                        onClick={() => {
                                            if (classDetail.offline_payment) window.location.href = classDetail.google_form_url
                                            else handleRegisterMember()
                                        }}
                                        disabled={
                                            (classDetail.is_registered?.status &&
                                                classDetail.is_registered?.status !== 'REJECTED') ||
                                            moment().isBefore(
                                                classDetail?.registration_start_time,
                                            ) ||
                                            moment().isAfter(classDetail?.registration_end_time)
                                        }
                                    >
                                        {classDetail.is_registered?.status === 'ACCEPTED'
                                            ? 'Terdaftar'
                                            : classDetail.quota === 0
                                                ? 'Sudah Penuh'
                                                : moment().isBefore(
                                                    classDetail?.registration_start_time,
                                                )
                                                    ? 'Pendaftaran belum dibuka'
                                                    : moment().isAfter(classDetail?.registration_end_time)
                                                        ? 'pendaftaran telah ditutup'
                                                        :
                                                        isLoggedIn ? 'Daftar'
                                                            : 'Daftar sebagai Anggota'}
                                        {
                                            classDetail.offline_payment ?
                                                <small style={{ fontSize: ".6em" }}><br />Via Formulir Google</small> : ""
                                        }
                                    </Button>

                                    {(classDetail.availability === 'OPEN_FOR_ALL' && !isLoggedIn) &&
                                        <a href={classDetail.google_form_url || ''} target="_blank"
                                            rel="noopener noreferrer">
                                            <Button
                                                style={{
                                                    fontFamily: 'Signika',
                                                    fontWeight: '600',
                                                    backgroundColor: Palette.PRIMARY,
                                                    borderColor: Palette.PRIMARY,
                                                    marginTop: 5,
                                                }}
                                                className="text-uppercase"
                                                disabled={
                                                    (classDetail.is_registered?.status &&
                                                        classDetail.is_registered?.status !== 'REJECTED') ||
                                                    moment().isBefore(
                                                        classDetail?.registration_start_time,
                                                    ) ||
                                                    moment().isAfter(classDetail?.registration_end_time)
                                                }
                                            >
                                                {classDetail.is_registered?.status === 'ACCEPTED'
                                                    ? 'Terdaftar'
                                                    : classDetail.quota === 0
                                                        ? 'Full Quota'
                                                        : moment().isBefore(
                                                            classDetail?.registration_start_time,
                                                        )
                                                            ? 'Registration has not opened'
                                                            : moment().isAfter(classDetail?.registration_end_time)
                                                                ? 'Registration has closed'
                                                                : isLoggedIn ? 'Daftar' : 'Daftar sebagai Non-anggota'}

                                                <small style={{ fontSize: ".6em" }}><br />Via Formulir Google</small>
                                            </Button>
                                        </a>
                                    }

                                    {classDetail.is_registered?.status === 'WAITING' && (
                                        <Button
                                            style={{
                                                fontFamily: 'Signika',
                                                fontWeight: '600',
                                                backgroundColor: 'green',
                                                marginTop: 5,
                                                marginLeft: 10,
                                            }}
                                            onClick={async () => {
                                                let url = classDetail.snap_payment_url
                                                if (!url) {
                                                    let m =
                                                        await Transaction.createMidtransTransaction(
                                                            classDetail.id,
                                                        )
                                                    url = m.snap_payment_url
                                                }
                                                window.location = url
                                            }}
                                        >
                                            Lanjut Bayar
                                        </Button>
                                    )}
                                    {classDetail.is_registered?.status === 'ACCEPTED' && (
                                        <>
                                            {classDetail.whatsapp_url && (
                                                <div
                                                    style={{
                                                        position: 'fixed',
                                                        bottom: isMobile ? '15px' : '30px',
                                                        right: '25px',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        zIndex: 9999999,
                                                    }}
                                                >
                                                    <a
                                                        href={classDetail.whatsapp_url}
                                                        target="_blank"
                                                        style={{ textDecorationLine: 'none' }}
                                                    >
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                alignItems: 'center',
                                                                justifyContent: 'flex-end',
                                                                marginTop: isMobile
                                                                    ? '15px'
                                                                    : '25px',
                                                            }}
                                                        >
                                                            {/*<div style={{*/}
                                                            {/*    color: "#25D366"*/}
                                                            {/*}}>*/}
                                                            {/*    <b>Whatsapp&nbsp;</b>*/}
                                                            {/*</div>*/}
                                                            <div
                                                                style={{
                                                                    backgroundColor: '#25D366',
                                                                    height: isMobile
                                                                        ? '55px'
                                                                        : '55px',
                                                                    width: isMobile
                                                                        ? '200px'
                                                                        : '200px',
                                                                    // width: isMobile ? '55px' : "70px",
                                                                    borderRadius: '55px',
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'flex-start',
                                                                    boxShadow:
                                                                        '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
                                                                    cursor: 'pointer',
                                                                    zIndex: '9999999',
                                                                    paddingLeft: '22px',
                                                                }}
                                                            >
                                                                <FaWhatsapp
                                                                    style={{
                                                                        fontSize: isMobile
                                                                            ? '25px'
                                                                            : '30px',
                                                                        color: 'white',
                                                                    }}
                                                                />
                                                                <b
                                                                    style={{
                                                                        color: 'white',
                                                                        marginLeft: '22px',
                                                                    }}
                                                                >
                                                                    Whatsapp
                                                                </b>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            )}

                                            {/*{showRecording && (*/}
                                            {/*    <>*/}
                                            {/*        {classDetail.recording_url_array &&*/}
                                            {/*        classDetail.recording_url_array.length && (*/}
                                            {/*            <>*/}
                                            {/*                <Button*/}
                                            {/*                    style={{*/}
                                            {/*                        fontFamily: 'Signika',*/}
                                            {/*                        fontWeight: '600',*/}
                                            {/*                        backgroundColor:*/}
                                            {/*                        Palette.PRIMARY,*/}
                                            {/*                        borderColor:*/}
                                            {/*                        Palette.PRIMARY,*/}
                                            {/*                    }}*/}
                                            {/*                    className="text-uppercase p-1 m-1"*/}
                                            {/*                    onClick={() =>*/}
                                            {/*                        setShowDownloadRecording(*/}
                                            {/*                            true,*/}
                                            {/*                        )*/}
                                            {/*                    }*/}
                                            {/*                >*/}
                                            {/*                    Watch Recording{' '}*/}
                                            {/*                    <SiGoogleclassroom/>*/}
                                            {/*                </Button>*/}
                                            {/*                {recordingModal(*/}
                                            {/*                    classDetail.recording_url_array,*/}
                                            {/*                    showDownloadRecording,*/}
                                            {/*                    setShowDownloadRecording,*/}
                                            {/*                )}*/}
                                            {/*            </>*/}
                                            {/*        )}*/}
                                            {/*    </>*/}
                                            {/*)}*/}

                                            {isValidMembership && classDetail?.is_registered?.certificate_url && (
                                                <a
                                                    href={
                                                        classDetail?.is_registered?.certificate_url
                                                    }
                                                    download
                                                >
                                                    <Button
                                                        style={{
                                                            fontFamily: 'Signika',
                                                            fontWeight: '600',
                                                            backgroundColor: Palette.PRIMARY,
                                                            borderColor: Palette.PRIMARY,
                                                            marginTop: 5,
                                                        }}
                                                        className="text-uppercase"
                                                    >
                                                        <FaDownload
                                                            size={14}
                                                            style={{ marginRight: 10 }}
                                                        />{' '}
                                                        Unduh Sertifikat
                                                    </Button>
                                                </a>
                                            )}

                                            {isValidMembership && classDetail.attachment_urls && (
                                                <>
                                                    <Button
                                                        style={{
                                                            fontFamily: 'Signika',
                                                            fontWeight: '600',
                                                            backgroundColor: Palette.PRIMARY,
                                                            borderColor: Palette.PRIMARY,
                                                            marginTop: 5,
                                                        }}
                                                        className="text-uppercase"
                                                        onClick={() =>
                                                            setShowDownloadMaterial(true)
                                                        }
                                                    >
                                                        <FaDownload
                                                            size={14}
                                                            style={{ marginRight: 10 }}
                                                        />{' '}
                                                        Unduh Materi
                                                    </Button>
                                                    {downloadModal(
                                                        classDetail.attachment_urls,
                                                        showDownloadMaterial,
                                                        setShowDownloadMaterial,
                                                    )}
                                                </>
                                            )}


                                            {isValidMembership && classHasStarted && (
                                                <a href={classDetail?.stream_url} target="_blank">
                                                    <Button
                                                        style={{
                                                            fontFamily: 'Signika',
                                                            fontWeight: '600',
                                                            backgroundColor: Palette.PRIMARY,
                                                            borderColor: Palette.PRIMARY,
                                                            marginTop: 5,
                                                        }}
                                                        className="text-uppercase"
                                                    >
                                                        <ImEnter
                                                            size={14}
                                                            style={{ marginRight: 10 }}
                                                        />
                                                        Join Class
                                                    </Button>
                                                </a>
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                        </Row>
                        {classContent}
                    </Col>
                </Row>

                <Row style={{ paddingTop: 40 }}>
                    <Col lg={12}>
                        <p
                            style={{
                                whiteSpace: 'pre-line',
                                wordWrap: 'break-word',
                                width: '300',
                                textAlign: 'justify',
                                textJustify: 'distribute',
                                textAlignLast: 'left',
                            }}
                        >
                            {classDetail.description}
                        </p>
                    </Col>
                </Row>

                {classDetail?.instructors?.length > 0 && (
                    <Row style={{ marginTop: 40 }}>
                        <Col>
                            <Accordion
                                defaultActiveKey={0}
                                onSelect={(selected) => {
                                    console.log('selected', selected)
                                }}
                            >
                                {classDetail?.instructors?.map((instructor, key) => {
                                    return (
                                        <Card>
                                            <Accordion.Toggle
                                                as={Card.Header}
                                                eventKey={key}
                                                style={{ backgroundColor: Palette.SECONDARY }}
                                            >
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <img
                                                        style={{
                                                            width: 50,
                                                            height: 50,
                                                            borderRadius: 45,
                                                            objectFit: 'cover',
                                                        }}
                                                        src={
                                                            instructor.instructor_image_url
                                                                ? instructor.instructor_image_url
                                                                : require('../assets/default_user.png')
                                                        }
                                                        alt="instructor"
                                                    />

                                                    <div
                                                        style={{
                                                            marginLeft: 15,
                                                            color: 'white',
                                                            fontFamily: 'OpenSans-SemiBold',
                                                            flex: 1,
                                                        }}
                                                    >
                                                        {instructor.name}

                                                        <div
                                                            style={{
                                                                fontFamily: 'OpenSans-Regular',
                                                            }}
                                                        >
                                                            {instructor.position}
                                                        </div>
                                                    </div>

                                                    {instructor.description && (
                                                        <>
                                                            <FaPlus color={'#e6e6e6'} />
                                                        </>
                                                    )}
                                                </div>
                                            </Accordion.Toggle>
                                        </Card>
                                    )
                                })}
                            </Accordion>
                        </Col>
                    </Row>
                )}
            </Container>
        </Container>
    )
}
