import Container from 'react-bootstrap/Container'
import React, {useEffect, useState} from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import moment from 'moment'

import Palette from '../Palette'
import {Tab, Tabs} from 'react-bootstrap'

import Class from '../models/Class'
import MemberShip from "../models/Membership";

function ActivityPage(props) {

    const [classes, setClasses] = useState([])
    const [myClasses, setMyClasses] = useState([])
    const [isMembershipActive, setIsMembershipActive] = useState(false)

    let isLoggedIn = !!localStorage.getItem('user')

    let noOfAcceptedClasses = 0;
    let noOfWaitingListClasses = 0;

    const getAllClass = async (membership) => {
        let classModel = new Class()

        try {
            if(isLoggedIn){

                let allMyClasses = await classModel.getAllMyClasses()
                
                allMyClasses.forEach(cls => {
                    if (cls.member_class?.status === 'WAITING') {
                        noOfWaitingListClasses++;
                    } else if (cls.member_class?.status === 'ACCEPTED') {
                        noOfAcceptedClasses++;
                    }
                })
                console.log('allMyClasses', allMyClasses)
                
                setMyClasses(allMyClasses)
            }

            let allClasses = await classModel.getAllClasses()

            console.log('allClasses', allClasses)
            setClasses(allClasses)
        } catch (e) {
            console.log('error: ' + e)
        }
    }

    const getMembershipData = async () => {
        let membershipModel = new MemberShip()

        try {
            let result = await membershipModel.getMembershipStatus()

            setIsMembershipActive(!!result.active)
            return !!result.active
        } catch (e) {
            console.log('error ' + e)
        }
    }

    const onUseEffect = async () => {
        let membership = await getMembershipData()
        getAllClass(membership)
    }

    useEffect(() => {
        onUseEffect()
    }, [])

    return (
        <Container className="text-left">
            <Tabs defaultActiveKey="coming" className="mb-3">
                <Tab eventKey="coming" title="Mendatang">
                    <Row>
                        {classes.length > 0 ?
                            classes.map((cls) => {
                                console.log(cls)

                                return (
                                    <Col
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            textAlign: 'center',
                                        }}
                                        onClick={() =>
                                            props.history.push(props.match.path + `/${cls.id}`)
                                        }
                                        className="mb-3"
                                        md={3}
                                        xs={6}
                                    >
                                        <div className="shadow-sm pb-2 w-100">
                                            <img
                                                style={{
                                                    objectFit: 'contain',
                                                    width: '100%',
                                                    minHeight: '200px',
                                                }}
                                                src={cls.class_image_url}
                                                alt={cls.name}
                                            />
                                            <div
                                                style={{
                                                    color: Palette.PRIMARY,
                                                    fontWeight: 'bold',
                                                    marginTop: 10
                                                }}
                                            >
                                                {cls.name}
                                            </div>
                                            <div style={{marginBottom: 20}}>
                                                {new moment(cls.start_time).format(
                                                    'DD-MMM-YYYY HH:mm',
                                                )}
                                            </div>
                                            <div
                                                style={{
                                                    backgroundColor:
                                                        cls.member_class?.status === 'ACCEPTED'
                                                            ? Palette.PRIMARY :
                                                            cls.quota === 0
                                                                ? Palette.GREY
                                                                :
                                                            cls.member_class?.status === 'REJECTED'
                                                                ? Palette.RED
                                                                : Palette.SECONDARY,
                                                    color: 'white',
                                                    // maxWidth: '100px',
                                                }}
                                                className={`text-center p-1 px-2 ${cls.member_class?.status === 'WAITING' ? 'w-75' : 'w-50'} m-auto`}
                                            >
                                                {

                                                    cls.member_class?.status === 'ACCEPTED' ? 'Telah Terdaftar' :
                                                        cls.quota === 0 ? 'Sudah Penuh' : cls.member_class?.status === 'WAITING' ? 'Menunggu Pembayaran' : cls.member_class?.status === 'REJECTED' ? 'Ditolak' : 'Tersedia'
                                                }
                                            </div>
                                        </div>
                                    </Col>
                                )
                            }) : <div style={{
                                height: 400,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                width: '100%'
                            }}>Saat ini, tidak ada Kegiatan Mendatang.</div>
                        }
                    </Row>           
                </Tab>
                {isLoggedIn && <Tab eventKey="my-event" title="Telah terdaftar">
                    <Row>
                        {
                                myClasses.length > 0 ?
                                    myClasses.map((cls) => {
                                        if (cls.member_class?.status === 'WAITING') return null;

                                        return (
                                            <Col
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    textAlign: 'center',
                                                }}
                                                md={3}
                                                xs={6}
                                                onClick={() =>
                                                    props.history.push(props.match.path + `/${cls.id}`)
                                                }
                                                className="mb-2"
                                            >
                                                <div className="shadow-sm pb-2 w-100">
                                                    <img
                                                        style={{
                                                            objectFit: 'contain',
                                                            width: '100%',
                                                        }}
                                                        src={cls.class_image_url}
                                                        alt={cls.name}
                                                    />
                                                    <div
                                                        style={{
                                                            color: Palette.PRIMARY,
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        {cls.name}
                                                    </div>
                                                    <div>
                                                        {new moment(cls.start_time).format(
                                                            'DD/MM/YYYY HH:mm',
                                                        )}
                                                    </div>
                                                </div>
                                            </Col>
                                        )
                                    })
                                    :
                                    <div style={{
                                        height: 300,
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "center"
                                    }}>Anda belum terdaftar di kelas mana pun</div>
                        }
                    </Row>
                </Tab>}

                {/*<Tab eventKey="waiting-list-event" title="Waiting List">*/}
                {/*    <Row>*/}
                {/*        {myClasses.length > 0 ?*/}
                {/*            myClasses.map((cls) => {*/}
                {/*                if(cls.member_class.status === 'ACCEPTED') return null;*/}

                {/*                return (*/}
                {/*                    <Col*/}
                {/*                        style={{*/}
                {/*                            display: 'flex',*/}
                {/*                            flexDirection: 'column',*/}
                {/*                            alignItems: 'center',*/}
                {/*                            textAlign: 'center',*/}
                {/*                        }}*/}
                {/*                        md={6}*/}
                {/*                        onClick={() =>*/}
                {/*                            props.history.push(props.match.path + `/${cls.id}`)*/}
                {/*                        }*/}
                {/*                        className="mb-2"*/}
                {/*                    >*/}
                {/*                        <div className="shadow-sm pb-2 w-100">*/}
                {/*                            <img*/}
                {/*                                style={{*/}
                {/*                                    objectFit: 'contain',*/}
                {/*                                    width: '100%',*/}
                {/*                                }}*/}
                {/*                                src={cls.class_image_url}*/}
                {/*                                alt={cls.name}*/}
                {/*                            />*/}
                {/*                            <div*/}
                {/*                                style={{*/}
                {/*                                    color: Palette.PRIMARY,*/}
                {/*                                    fontWeight: 'bold',*/}
                {/*                                }}*/}
                {/*                            >*/}
                {/*                                {cls.name}*/}
                {/*                            </div>*/}
                {/*                            <div>*/}
                {/*                                {new moment(cls.start_time).format(*/}
                {/*                                    'DD/M/YYYY HH:mm',*/}
                {/*                                )}*/}
                {/*                            </div>*/}
                {/*                        </div>*/}
                {/*                    </Col>*/}
                {/*                )*/}
                {/*            })*/}
                {/*            :*/}
                {/*            <div style={{*/}
                {/*                height : 300,*/}
                {/*                width : "100%",*/}
                {/*                display : "flex",*/}
                {/*                flexDirection : "column",*/}
                {/*                alignItems : "center",*/}
                {/*                justifyContent : "center"*/}
                {/*            }}>You have not been registered to a class</div>*/}
                {/*        }*/}
                {/*    </Row>*/}
                {/*</Tab>*/}
            </Tabs>
        </Container>
    )
}

export default ActivityPage
