import React, { useEffect, useState } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'

import Palette from '../Palette'
import { useParams, Link } from 'react-router-dom'
import Member from "../models/Member";

import profile from '../assets/default_user.png'
import Spinner from "react-bootstrap/Spinner";
import parse from "html-react-parser";
import moment from "moment"
import { AiOutlineLink, FaDownload, FaTrash } from "react-icons/all";
import TextTranslator from "../util/TextTranslator";

export default function ResultDetailPage(props) {

    const { id } = useParams()

    let member = new Member()

    const [currentMember, setCurrentMember] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        getMemberData()
    }, [])

    const getMemberData = async () => {

        try {
            let memberData = await member.getMemberById(id)
            console.log("MEMBERDATA", memberData)
            setCurrentMember(memberData)
        } catch (e) {
            console.error(e)
        }

    }

    const result = {
        id: 'HPI-01-04-0073',
        main_photo_url: profile,
        name: 'Larry McLovin',
        profession: ['Translator', 'Interpreter'],
        description:
            'He has suspenders, a desk, a TV show, different color lights behind him, and a marquee at the bottom of the screen saying what was just said even though you just heard him.',
        specialization:
            'Art - Architecture, Automotive, Agriculture, Banking, Books-Fiction, Books-Non-Fiction, Business and Commerce, Culture, Education, Electrical - Electronics, Engineering, Environment, Fashion - Clothing, Film - TV, Finance, Food Industry, Forestry, Games and Entertainment, General, Geography, Government - Politics, Hospitality Industry, Law Enforcement, Legal - Contracts, Legal - General, Legal - Law, Literature - Poetry, Management, Marketing, Military - Defense, Mining-Mineral and Coal, Music, Oil and Gas, Religion, Science, Soc',
        languages: ['English', 'Indonesian'],
        services:
            'Translation, Editing, Proofreading, Localization, Subtitling, Transcription, Consecutive Interpreting, Simultaneous Interpreting',
        tools: 'Trados Studio, Wordfast Classic, OmegaT, Other',
        education:
            'Bachelor Degree, Management Faculty, Management Banking, ABFI Institute (STIE Perbanas)',
        full_time: true,
        occupation: 'Freelance',
        experience: 'Translator since June 1994',
        member: [
            'ProZ: http://www.proz.com/profile/1176796 ',
            'TranslatorsCafe: http://RURYDA.TranslatorsCafe.com',
        ],
        currencies: ['IDR', 'USD', 'EUR', 'GBP'],
        payment: ['Domestic bank transfer, International wire transfer', 'Paypal', 'Other'],
    }

    return (
        <>
            <Container
                style={{
                    backgroundColor: 'white',
                    borderRadius: 10,
                    color: '#363636',
                    marginTop: 30,
                }}
            >
                <Row>
                    <Col md={4} className="shadow-sm pb-2 mr-3 h-100">
                        <div className="d-block text-center">
                            <img
                                src={currentMember?.profile_picture_url ? currentMember.profile_picture_url : profile}
                                style={{
                                    maxWidth: '100%',
                                    objectFit: 'contain',
                                }}
                                alt={currentMember?.full_name}
                            />
                            <div className="my-2">
                                <b>{currentMember?.hpi_id}</b>
                            </div>

                            <div className="my-2">Member since {moment(currentMember?.approval_date).format("YYYY")}</div>

                            {
                                currentMember ?
                                    <a href={`mailto:${currentMember.email}`}>
                                        <Button
                                            style={{
                                                fontFamily: 'Signika',
                                                fontWeight: 'bold',
                                                paddingTop: 10,
                                                paddingBottom: 10,
                                                width: '100%',
                                                backgroundColor: Palette.PRIMARY,
                                                borderColor: Palette.PRIMARY,
                                                borderRadius: '20px',
                                            }}
                                            className="my-2"
                                        >
                                            KIRIM PESAN
                                        </Button>
                                    </a>
                                    :
                                    ""
                            }


                        </div>
                    </Col>
                    <Col className="shadow-sm d-block">
                        {
                            isLoading ?
                                <Spinner style={{ width: '4rem', height: '4rem', marginLeft: '47%', marginTop: '5%', marginBottom: '5%' }}
                                    animation="border" variant="danger" />
                                :
                                currentMember ?
                                    <>
                                        <h1
                                            style={{
                                                fontFamily: 'Roboto',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            {currentMember.full_name}
                                        </h1>

                                        <div className="d-flex mb-5">
                                            {
                                                (currentMember.profession === "both" || currentMember.profession === "Both") ?
                                                    <>
                                                        <div
                                                            className="px-2 mr-1"
                                                            style={{
                                                                color: '#224AD7',
                                                                borderColor: '#224AD7',
                                                                borderRadius: '20px',
                                                                border: 'thin solid',
                                                            }}
                                                        >
                                                            Juru Bahasa
                                                        </div>
                                                        <div
                                                            className="px-2 mr-1"
                                                            style={{
                                                                color: '#224AD7',
                                                                borderColor: '#224AD7',
                                                                borderRadius: '20px',
                                                                border: 'thin solid',
                                                            }}
                                                        >
                                                            Penerjemah
                                                        </div>
                                                    </>
                                                    :
                                                    <div
                                                        className="px-2 mr-1"
                                                        style={{
                                                            color: '#224AD7',
                                                            borderColor: '#224AD7',
                                                            borderRadius: '20px',
                                                            border: 'thin solid',
                                                        }}
                                                    >
                                                        {TextTranslator.getProfesi(currentMember.profession)}
                                                    </div>
                                            }
                                        </div>
                                        <div className="my-3">
                                            <div>
                                                <b>Resume</b>
                                            </div>
                                            {currentMember.resume ? parse(currentMember.resume) : "-"}
                                        </div>
                                        <div className="my-3">
                                            <div className="mb-1">
                                                <b>Spesialisasi</b>
                                            </div>
                                            {currentMember.specializations ? currentMember.specializations.map((obj, idx) => {
                                                return <>{obj.specialization}{idx < currentMember.specializations.length - 1 ? ", " : ""}</>
                                            }) : ""}
                                        </div>
                                        <div className="my-3">
                                            <div className="mb-1">
                                                <b>Pasangan Bahasa</b>
                                            </div>
                                            {currentMember.languages ? currentMember.languages.map((lang) => {
                                                return <div>{lang.language_from} - {lang.language_to} {(lang.certification === "NONE") || (lang.certification === null) ? "" : "(HPI Certification: " + lang.certification + ")"}<br /></div>
                                            }) : ""}
                                        </div>
                                        <div className="my-3">
                                            <div className="mb-1">
                                                <b>Sertifikasi</b>
                                            </div>
                                            {currentMember?.member_certificates?.map((v) => {
                                                return (
                                                    <>
                                                        <div>

                                                        <span className='mr-3'> {v?.certification_type}</span><span className="badge badge-primary mr-3">{v?.certification_number}</span>  <span>

                                                            </span>
                                                            {v?.valid_until == null ? null : <p className='text-secondary' style={{ fontSize: '.7em' }}>Berlaku Sampai : {moment(v?.valid_until).format('DD MMM YYYY')}</p>}
                                                        </div>

                                                    </>
                                                )
                                            })}
                                        </div>
                                        <div className="my-3">
                                            <div className="mb-1">
                                                <b>Layanan</b>
                                            </div>
                                            {currentMember.service_offered ? parse(currentMember.service_offered) : "-"}
                                        </div>
                                        <div className="my-3">
                                            <div className="mb-1">
                                                <b>Perangkat Lunak</b>
                                            </div>
                                            {currentMember.software ? currentMember.software : "-"}
                                        </div>
                                        <div className="my-3">
                                            <div className="mb-1">
                                                <b>Pendidikan</b>
                                            </div>
                                            {currentMember.education ? parse(currentMember.education) : "-"}
                                        </div>
                                        <div className="my-3">
                                            <div className="mb-1">
                                                <b>Ketersediaan</b>
                                            </div>
                                            {currentMember.availability ? TextTranslator.getKetersediaan(currentMember.availability) : '-'}
                                        </div>
                                        <div className="my-3">
                                            <div className="mb-1">
                                                <b>Status Pekerjaan</b>
                                            </div>
                                            {currentMember.occupation ? TextTranslator.getStatusPekerjaan(currentMember.occupation) : "-"}
                                        </div>
                                        <div className="my-3" style={{
                                            whiteSpace: "pre-line"
                                        }}>
                                            <div className="mb-1">
                                                <b>Pengalaman Kerja</b>
                                            </div>
                                            {
                                                (currentMember.profession === "Both" || currentMember.profession === "Interpreter" || currentMember.profession === "both" || currentMember.profession === "interpreter")
                                                    ?
                                                    currentMember.translator_since ? `Juru Bahasa sejak ${currentMember.interpreter_since}` : "-"
                                                    : ""
                                            }
                                            {
                                                (currentMember.profession === "Both" || currentMember.profession === "Translator" || currentMember.profession === "both" || currentMember.profession === "translator")
                                                    ?
                                                    currentMember.translator_since ? `${(currentMember.profession === "Both" || currentMember.profession === "both") ? "\n" : ""}Penerjemah sejak ${currentMember.translator_since}` : "-"
                                                    : ""
                                            }
                                        </div>
                                        {/*<div className="my-3">*/}
                                        {/*    <div className="mb-1">*/}
                                        {/*        <b>Member of </b>*/}
                                        {/*    </div>*/}
                                        {/*    {result.member.map((mem) => {*/}
                                        {/*        return <div className="px-2 mr-1">{mem}</div>*/}
                                        {/*    })}*/}
                                        {/*</div>*/}
                                        <div className="my-3">
                                            <div className="mb-1">
                                                <b>Preferensi Mata Uang</b>
                                            </div>
                                            {currentMember.preferred_currency ? currentMember.preferred_currency : "-"}
                                        </div>
                                        <div className="my-3">
                                            <div className="mb-1">
                                                <b>Metode Pembayaran</b>
                                            </div>
                                            {currentMember.payment_method ? currentMember.payment_method : "-"}
                                        </div>
                                        <div className="my-3">
                                            <div className="mb-1">
                                                <b>Anggota dari</b>
                                            </div>
                                            {
                                                (Array.isArray(currentMember.memberscol) && currentMember.memberscol.length > 0) ? currentMember.memberscol.map((obj, idx) => {
                                                    return <>
                                                        <div style={{
                                                            backgroundColor: Palette.PRIMARY,
                                                            borderRadius: 5,
                                                            display: "inline-block",
                                                            minWidth: 100,
                                                            color: "white",
                                                            textAlign: "center",
                                                            paddingTop: 3,
                                                            paddingBottom: 3,
                                                            paddingRight: 5,
                                                            paddingLeft: 5,
                                                        }}>
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                alignItems: "center",
                                                                justifyContent: "center"
                                                            }}>
                                                                <div style={{
                                                                    flex: 1
                                                                }}>{obj.organization}</div>

                                                                <div style={{
                                                                    alignItems: "center",
                                                                    justifyContent: "center"
                                                                }}>
                                                                    <a href={obj.url} target={"_blank"}>
                                                                        <AiOutlineLink
                                                                            style={{
                                                                                color: "white",
                                                                                marginRight: 5,
                                                                                marginLeft: 10
                                                                            }}
                                                                        />
                                                                    </a>

                                                                </div>

                                                            </div>


                                                        </div>

                                                        {/*<a href={obj.location}>*/}
                                                        {/*    <Button*/}
                                                        {/*        size={"sm"}*/}
                                                        {/*        style={{*/}
                                                        {/*            */}
                                                        {/*            borderColor: Palette.PRIMARY,*/}
                                                        {/*        }}*/}
                                                        {/*    >*/}
                                                        {/*        */}
                                                        {/*    </Button>*/}
                                                        {/*</a>*/}

                                                    </>
                                                })
                                                    :
                                                    "-"
                                            }
                                        </div>
                                        <div className="my-3">
                                            <div className="mb-1">
                                                <b>Berkas</b>
                                            </div>
                                            {
                                                (Array.isArray(currentMember.files) && currentMember.files.length > 0) ? currentMember.files.map((obj, idx) => {
                                                    return <>
                                                        <div style={{
                                                            backgroundColor: Palette.PRIMARY,
                                                            borderRadius: 5,
                                                            display: "inline-block",
                                                            minWidth: 100,
                                                            color: "white",
                                                            textAlign: "center",
                                                            paddingTop: 3,
                                                            paddingBottom: 3,
                                                            paddingRight: 5,
                                                            paddingLeft: 5,
                                                        }}>
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                alignItems: "center",
                                                                justifyContent: "center"
                                                            }}>
                                                                <div style={{
                                                                    flex: 1
                                                                }}>{obj.name}</div>

                                                                <div style={{
                                                                    alignItems: "center",
                                                                    justifyContent: "center"
                                                                }}>
                                                                    <a href={obj.location}>
                                                                        <FaDownload
                                                                            style={{
                                                                                color: "white",
                                                                                marginRight: 5,
                                                                                marginLeft: 10
                                                                            }}
                                                                        />
                                                                    </a>

                                                                </div>

                                                            </div>


                                                        </div>

                                                        {/*<a href={obj.location}>*/}
                                                        {/*    <Button*/}
                                                        {/*        size={"sm"}*/}
                                                        {/*        style={{*/}
                                                        {/*            */}
                                                        {/*            borderColor: Palette.PRIMARY,*/}
                                                        {/*        }}*/}
                                                        {/*    >*/}
                                                        {/*        */}
                                                        {/*    </Button>*/}
                                                        {/*</a>*/}

                                                    </>
                                                })
                                                    :
                                                    "-"
                                            }
                                        </div>
                                    </>
                                    :
                                    <div
                                        style={{
                                            width: '100%',
                                            textAlign: 'center',
                                            marginTop: 10,
                                            marginBottom: 20,
                                            height: "100%",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center"
                                        }}
                                    >
                                        <div
                                            style={{
                                                fontFamily: 'Signika',
                                                fontWeight: '600',
                                                color: 'grey',
                                                fontSize: '1.5em',
                                            }}
                                        >
                                            No Search Result
                                        </div>
                                    </div>
                        }

                    </Col>
                </Row>
            </Container>
        </>
    )
}
