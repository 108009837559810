import Container from 'react-bootstrap/Container'
import React, { useEffect, useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { FormControl, InputGroup, Form } from 'react-bootstrap'
import {
    MdEmail,
    MdHome,
    MdLocationOn,
    MdLocationCity,
    MdPermContactCalendar,
    MdLock,
    MdPerson,
    MdWork,
    MdPhone,
    MdCardMembership,
    MdPhoneIphone,
    MdHelp,
} from 'react-icons/md'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { IoMdEye, IoMdEyeOff, IoMdCalendar } from 'react-icons/io'
import Location from '../models/Location'
import Select, { components } from 'react-select'
import Spinner from 'react-bootstrap/Spinner'
import { FaAngleDown } from 'react-icons/fa'
import Button from 'react-bootstrap/Button'

import COUNTRIES_JSON from '../assets/countries/countries.json'
import Palette from '../Palette'
import Specialization from '../models/Specialization'
import Language from '../models/Language'
import Member from '../models/Member'
import Cities from '../util/CityData'
import Provinces from '../util/ProvinceData'
import Swal from 'sweetalert2'
import Alert from '../components/Alert'
import { useHistory } from 'react-router-dom'
import { AiFillCloseCircle, FaMinus, FaPlus } from 'react-icons/all'
import IconButton from '@material-ui/core/IconButton'
import MomentUtils from '@date-io/moment';
import {
    DatePicker,
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import '../pages/css/register.css'
import Upload from '../models/Upload'
import moment from "moment"
import { Radio } from '@material-ui/core'
import CustomDropzone from "../components/Reusable/CustomDropzone";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/bootstrap.css'

const translatorFullMemberReqOptions = [
    {
        label: '1. Telah menerjemahkan buku dan diterbitkan oleh penerbit terdaftar IKAPI',
        value: '1',
    },
    {
        label: '2. Melampirkan contoh terjemahan (beserta teks sumber) dengan volume 150.000 karakter termasuk spasi',
        value: '2',
    },
    { label: '3. Merupakan Penerjemah Tersumpah (SK Mentri)', value: '3' },
    { label: '4. Merupakan Penerjemah Tersumpah (SK Gubernur DKI Jakarta)', value: '4' },
    {
        label: '5. Telah bekerja (minimal) satu tahun di sebuah perusahaan sebagai penerjemah atau jabatan terkait penerjemah',
        value: '5',
    },
    { label: '6. Surat rujukan/referensi dari 3(tiga) klien yang berbeda', value: '6' },
]


const CITIZENSHIP_OPTION = [
    { label: 'WNI', value: 'INDONESIAN' },
    { label: 'WNA', value: 'Foreign' },
]

function RegisterPage(props) {
    const history = useHistory()

    const DropdownIndicator = (props) => {
        return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    <FaAngleDown color={'grey'} />
                </components.DropdownIndicator>
            )
        )
    }

    let specializationModel = new Specialization()
    let languageModel = new Language()

    const [isPasswordVisible, setPasswordVisible] = useState(false)
    const [isPasswordConfirmVisible, setPasswordConfirmVisible] = useState(false)
    const [isButtonHovered, setButtonHovered] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const [errorMsg, setErrorMsg] = useState(null)

    const [isHonorificFocused, setHonorifcFocused] = useState(false)
    const [isFullNameFocused, setFullNameFocused] = useState(false)
    const [isEmailFocused, setEmailFocused] = useState(false)
    const [isPasswordFocused, setPasswordFocused] = useState(false)
    const [isPasswordConfirmFocused, setPasswordConfirmFocused] = useState(false)
    const [isAddressFocused, setAddressFocused] = useState(false)
    const [isCityFocused, setCityFocused] = useState(false)
    const [isPostalCodeFocused, setPostalCodeFocused] = useState(false)
    const [honorific, setHonorific] = useState('')
    const [fullName, setFullName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [passwordConfirm, setPasswordConfirm] = useState('')
    const [gender, setGender] = useState('')
    const [address, setAddress] = useState('')
    const [province, setProvince] = useState('')
    const [city, setCity] = useState({})
    const [postalCode, setPostalCode] = useState('')
    const [citizenship, setCitizenship] = useState('INDONESIAN')
    const [profession, setProfession] = useState('')
    const [birthPlace, setBirthPlace] = useState('')
    const [availability, setAvailability] = useState('')
    const [birthDate, setBirthDate] = useState(new Date())
    const [nationality, setNationality] = useState('Indonesia')
    const [idNumber, setIdNumber] = useState('')
    const [idOrigin, setIdOrigin] = useState('')
    const [currentAddress, setCurrentAddress] = useState('')
    const [homePhone, setHomePhone] = useState('')
    const [mobilePhone, setMobilePhone] = useState('')
    const [specializations, setSpecializations] = useState([])
    const [profilePic, setProfilePic] = useState(null)
    const [memberType, setMemberType] = useState('')
    const [agencyNamePlace, setAgencyNamePlace] = useState('')
    const [translatorFullMemberReq, setTranslatorFullMemberReq] = useState([])
    const [additionalTranslatorFullMemberReq, setAdditionalTranslatorFullMemberReq] = useState('')
    const [interpreterFullMemberReq, setInterpreterFullMemberReq] = useState([])
    const [agreementUnderstandingDeclaration, setAgreementUnderstandingDeclaration] = useState('')
    const [profileAgreement, setProfileAgreement] = useState('')
    const [complianceAgreement, setComplianceAgreement] = useState('')
    const [boundAgreement, setBoundAgreement] = useState('')
    const [shortProfile, setShortProfile] = useState('')
    const [joinReason, setJoinReason] = useState('')
    const [tnCAgreement, setTnCAgreement] = useState(false)

    const [honorificError, setHonorificError] = useState('')
    const [fullNameError, setFullNameError] = useState('')
    const [emailError, setEmailError] = useState('')
    const [passwordError, setPasswordError] = useState('')
    const [passwordConfirmError, setPasswordConfirmError] = useState('')
    const [genderError, setGenderError] = useState('')
    const [addressError, setAddressError] = useState('')
    const [professionError, setProfessionError] = useState('')
    const [birthPlaceError, setBirthPlaceError] = useState('')
    const [birthDateError, setBirthDateError] = useState('')
    const [nationalityError, setNationalityError] = useState('')
    const [idNumberError, setIdNumberError] = useState('')
    const [idOriginError, setIdOriginError] = useState('')
    const [currentAddressError, setCurrentAddressError] = useState('')
    const [homePhoneError, setHomePhoneError] = useState('')
    const [mobilePhoneError, setMobilePhoneError] = useState('')
    const [specializationsError, setSpecializationsError] = useState([])
    const [profilePicError, setProfilePicError] = useState([])
    const [memberTypeError, setMemberTypeError] = useState('')
    const [agencyNamePlaceError, setAgencyNamePlaceError] = useState('')
    const [translatorFullMemberReqError, setTranslatorFullMemberReqError] = useState('')
    const [additionalTranslatorFullMemberReqError, setAdditionalTranslatorFullMemberReqError] =
        useState('')
    const [interpreterFullMemberReqError, setInterpreterFullMemberReqError] = useState('')
    const [agreementUnderstandingDeclarationError, setAgreementUnderstandingDeclarationError] =
        useState('')
    const [profileAgreementError, setProfileAgreementError] = useState('')
    const [complianceAgreementError, setComplianceAgreementError] = useState('')
    const [boundAgreementError, setBoundAgreementError] = useState('')
    const [shortProfileError, setShortProfileError] = useState('')
    const [joinReasonError, setJoinReasonError] = useState('')
    const [languageToError, setLanguageToError] = useState(null)
    const [languageFromError, setLanguageFromError] = useState(null)
    const [citizenError, setCitizenError] = useState('')
    const [provinceError, setProvinceError] = useState('')
    const [cityError, setCityError] = useState('')
    const [selectedLanguagePairError, setSelectedLanguagePairError] = useState('')
    const [tnCAgreementError, setTnCAgreementError] = useState(false)


    const [selectedSpecializations, setSelectedSpecializations] = useState([])
    const [selectedCity, setSelectedCity] = useState('')
    const [selectedLanguages, setSelectedLanguages] = useState([])
    const [type, setType] = useState('')
    const [selectedProvince, setSelectedProvince] = useState(null)

    const [countries, setCountries] = useState([])
    const [languages, setLanguages] = useState([])
    const [provinces, setProvinces] = useState([])
    const [cities, setCities] = useState([])

    const [translatorFullMemberReqLabel, setTranslatorFullMemberReqLabel] = useState(
        translatorFullMemberReqOptions[0].label,
    )
    const [selectedLanguagePair, setSelectedLanguagePair] = useState([])
    const [languageSelection, setLanguageSelection] = useState([])
    const [languageFrom, setLanguageFrom] = useState(null)
    const [languageTo, setLanguageTo] = useState(null)
    const [fileRegReqType, setFileRegReqType] = useState("1")
    const ATTACHMENT_TRANSLATOR = 'translator',
        ATTACHMENT_INTERPRETER = 'interpreter';

    const [translatorFileLists, setTranslatorFileList] = useState([])

    const addFileList = (type, value) => {

    }

    // const fillDummy = () => {
    //     setFullName('Robby Harayanto')
    //     setEmail('robbysoftdev+99@gmail.com')
    //     setHomePhone('0212918163120')
    //     setMobilePhone('0821919199191')
    //     setPassword('123')
    //     setPasswordConfirm('123')
    //     setGender('M')
    //     setIdNumber('1291291921929129')
    // }

    useEffect(() => {
        if (languageFrom && languageTo) {
            let found = false

            selectedLanguagePair.forEach((languagePair) => {
                if (
                    languagePair.language_from.value === languageFrom.value &&
                    languagePair.language_to.value === languageTo.value
                ) {
                    found = true
                }
            })

            if (!found) {
                setSelectedLanguagePair([
                    ...selectedLanguagePair,
                    {
                        language_from: languageFrom,
                        language_to: languageTo,
                    },
                ])
                setLanguageFrom(null)
                setLanguageTo(null)
            }
        }
    }, [languageFrom, languageTo])

    const getAllSpecialization = async () => {
        try {
            const specializations = await specializationModel.getAllSpecialization()

            setSpecializations(specializations)
        } catch (e) {
            console.log(e)
        }
    }

    const getAllLanguages = async () => {
        try {
            const languages = await languageModel.getAllLanguages()

            setLanguages(languages)

            setLanguageSelection(
                languages.map((obj) => {
                    return {
                        value: obj.id,
                        label: obj.language,
                    }
                }),
            )

            console.log(languages)
        } catch (e) {
            console.log(e)
        }
        setCountries(COUNTRIES_JSON.map((cj) => {
            return { label: cj.name, value: cj.name }
        }))
    }

    const handleInput = (event) => {
        if (event.target.name === 'memberType') {
            if (event.target.value === 'MUDA') {
                setTranslatorFullMemberReq([])
                setInterpreterFullMemberReq([])
            }
            setMemberType(event.target.value)
        }

        console.log('selected', {
            name: event.target.name,
            value: event.target.value
        })
        if (event.target.name === 'profession') {
            if (event.target.value === 'Translator') {
                setInterpreterFullMemberReq([])
            } else if (event.target.value === 'Interpreter') {
                setTranslatorFullMemberReq([])
            }
            setProfession(event.target.value)
        }
        else if (event.target.name === 'specialization') {
            if (event.target.checked)
                setSelectedSpecializations([...selectedSpecializations, event.target.value])
            else if (!event.target.checked) {
                setSelectedSpecializations(
                    selectedSpecializations.filter((value) => value !== event.target.value),
                )
            }
        } else if (event.target.name === 'language') {
            if (event.target.checked)
                setSelectedLanguages([...selectedLanguages, event.target.value])
            else if (!event.target.checked) {
                setSelectedCity(selectedLanguages.filter((value) => value !== event.target.value))
            }
        } else if (event.target.name === 'gender') {
            setGender(event.target.value)
        } else if (event.target.name === 'availability') {
            setAvailability(event.target.value)
        }
    }

    const findProvinces = async (user) => {
        let model = new Location()

        try {
            let result = await model.getAllProvince()
            console.log(result)
            const provinces = []

            result.results.map((province) => {
                if (user && user.province === province.province) {
                    setProvince(province.province)
                    setSelectedProvince({ value: province.province_id, label: province.province })
                    onProvinceChange(province.province_id)
                    // [currentData, ...provinces]
                }
                provinces.push({ value: province.province_id, label: province.province })
            })
            console.log(provinces)
            setProvinces(provinces)
        } catch (e) {
            console.log('e', e)
        }
    }

    const handleUpload = (_params) => {
        switch (_params) {
            case 'syarat_1':
                break
            case 'syarat_2':
                break
            case 'syarat_3':
                break
            case 'syarat_4':
                break
            case 'syarat_5':
                break
            case 'syarat_pendaftaran':
                break
            default:
                return
        }
    }
    // const onProvinceChange = async (target) => {
    //
    //     console.log('onProvinceChange has been initiated!')
    //     let targetArr = target.split(',');
    //     targetArr[0] = parseInt(targetArr[0]);
    //
    //     setSelectedCity(0)
    //
    //     console.log('selected city', selectedCity)
    //
    //     console.log(targetArr[0])
    //
    //     let model = new Location();
    //
    //     let cityQueryResult = await model.getCityById(target);
    //
    //     const cities = [];
    //
    //     console.log('cityQueryResult', cityQueryResult)
    //
    //     let user = {};
    //
    //     if (JSON.parse(localStorage.getItem('user'))) user = JSON.parse(localStorage.getItem('user'));
    //
    //     cityQueryResult.rajaongkir.results.map(city => {
    //         if (city.type + " " + city.city_name === user.city) {
    //             setSelectedCity({value: city.city_id, label: city.type + " " + city.city_name})
    //             setCity(city.city_name)
    //         }
    //
    //         cities.push({value: city.city_id, label: city.type + " " + city.city_name})
    //     })
    //     setCities([])
    //     setCities(cities);
    // }

    useEffect(() => {
        getAllSpecialization()
        setProvinces(
            Provinces.map((value) => ({ label: value.province, value: value.province_id })),
        )
    }, [])

    useEffect(() => {
        getAllLanguages()
    }, [])

    useEffect(() => {
        if (localStorage.getItem('user')) {
            props.history.replace('/')
        }
    }, [])

    useEffect(() => {
        if (errorMsg) {
            window.scrollTo(0, 0)
        }
    }, [errorMsg])

    const onProvinceChange = (target) => {
        setCities([])
        setSelectedCity('')
        setCity('')

        console.log('onProvinceChange has been initiated!')
        console.log(target)
        console.log(city)
        console.log([...Cities.filter((city) => city.province_id === target)])

        setCities([
            ...Cities.filter((city) => city.province_id === target).map((value) => ({
                label: value.type? (value.type + ' ' + value.city_name) : value.city_name,
                value: value.city_id,
                province_id: value.province_id,
            })),
        ])
    }

    const onImagePicked = async function (image) {

        try {
            const model = new Upload();

            const formData = new FormData();

            formData.append('upload', image, image.name)
            formData.append('member_id', "-1")

            const result = await model.uploadImage(formData);
            console.log(result)
            setProfilePic(result.location)

        } catch (e) {
            console.error(e)
        }


    }

    const handleUploadInterpreterReq = async (event) => {
        console.log(event)

        try {
            const model = new Upload()

            const formData = new FormData()

            console.log('ETF', event.target.files[0])

            formData.append('upload', event.target.files[0])
            formData.append('member_id', '-1')

            const result = await model.uploadImage(formData)
            let obj = {
                id: interpreterFullMemberReq.length + 1,
                name: event.target.files[0].name,
                location: result?.location
            }
            // setInterpreterFullMemberReq(result.location)
            setInterpreterFullMemberReq([...interpreterFullMemberReq, obj])
        } catch (e) {
            console.log('ETF ERROR', e)
        }
    }

    const handleUploadTranslatorReq = async (event) => {
        console.log(event)

        try {
            const model = new Upload()

            const formData = new FormData()

            console.log('ETF', event.target.files[0])

            formData.append('upload', event.target.files[0])
            formData.append('member_id', '-1')

            const result = await model.uploadImage(formData)
            console.log('eft result', result)
            let obj = {
                id: translatorFullMemberReq.length + 1,
                name: event.target.files[0].name,
                location: result?.location
            }
            setTranslatorFullMemberReq([...translatorFullMemberReq, obj])

        } catch (e) {
            console.log('ETF ERROR', e)
        }
    }

    const attemptRegister = async () => {
        setErrorMsg(null)

        setHonorific("");

        let model = new Member()

        if (fullName.length === 0) {
            Swal.fire('', 'Harap isi Nama Lengkap', 'error')
            return
        }

        if (email.length === 0) {
            Swal.fire('', 'Harap isi Surel', 'error')
            return
        }

        if (mobilePhone.length === 0) {
            Swal.fire('', 'Harap Isi Nomor Telepon Seluler', 'error')
            return
        }

        if (password.length === 0) {
            Swal.fire('', 'Harap isi Kata Sandi', 'error')
            return
        }

        if (passwordConfirm.length === 0) {
            Swal.fire('', 'Harap isi Konfirmasi Kata Sandi', 'error')
            return
        }

        if (password !== passwordConfirm) {
            Swal.fire('', 'Kata Sandi dan Konformasi Kata Sandi Tidak Sesuai', 'error')
            return
        }

        if (gender.length === 0) {
            Swal.fire('', 'Harap pilih Jenis Kelamin', 'error')
            return
        }

        if (birthPlace.length === 0) {
            Swal.fire('', 'Harap isi Tempat Lahir', 'error')
            return
        }

        if (birthDate.length === 0) {
            Swal.fire('', 'Harap pilih Tanggal Lahir', 'error')
            return
        }

        if (citizenship.length === 0) {
            Swal.fire('', 'Harap Pilih Kewarganegaraan', 'error')
            return
        }

        if (idNumber.length === 0) {
            Swal.fire('', 'Harap isi Nomor KTP/PASPOR', 'error')
            return
        }

        if (idOrigin.length === 0) {
            Swal.fire('', 'Harap isi Tempat KTP diterbitkan', 'error')
            return
        }

        if (province.length === 0) {
            Swal.fire('', 'Harap pilih Provinsi', 'error')
            return
        }

        if (city.length === 0) {
            Swal.fire('', 'Harap pilih Kota/Kabupaten', 'error')
            return
        }

        if (address.length === 0) {
            Swal.fire('', 'Harap isi Alamat Lengkap', 'error')
            return
        }

        if (memberType.length === 0) {
            Swal.fire('', 'Harap pilih Jenis Keanggotaan', 'error')
            return
        }

        if (profession.length === 0) {
            Swal.fire('', 'Harap pilih Profesi', 'error')
            return
        }

        if (availability.length === 0) {
            Swal.fire('', 'Harap pilih Ketersediaan', 'error')
            return
        }

        if (selectedLanguagePair.length === 0) {
            Swal.fire('', 'Harap tentukan Pasangan Bahasa', 'error')
            return
        }

        if (!boundAgreement && memberType === 'PENUH') {
            Swal.fire('', 'Harap menyetujui pernyataan keterikatan', 'error')
            return
        }

        if (!complianceAgreement && memberType === 'PENUH') {
            Swal.fire('', 'Harap menyetujui pernyataan kepatuhan', 'error')
            return
        }

        if (!agreementUnderstandingDeclaration && memberType === 'PENUH') {
            Swal.fire('', 'Harap menyetujui Pernyataan Pemahan dan Kebenaran Informasi', 'error')
            return
        }

        if (shortProfile.length === 0) {
            Swal.fire('', 'Harap mengisi Riwayat Hidup Singkat', 'error')
            return
        }
        if (memberType === 'PENUH' && profileAgreement.length === 0) {
            Swal.fire('', 'Harap memilih Kebersediaan untuk menampilkan profil', 'error')
            return
        }
        if (joinReason.length === 0) {
            Swal.fire('', 'Harap mengisi Alasan Begabung', 'error')
            return
        }

        if (!tnCAgreement) {
            Swal.fire('', 'Harap menyetujui Syarat & Ketentuan yang berlaku di HPI', 'error')
            return
        }

        setLoading(true)

        try {
            let payload = {
                honorific,
                email,
                password,
                availability,
                full_name: fullName,
                gender,
                address: currentAddress,
                birth_place: birthPlace,
                profession,
                ssn: idNumber,
                ss_created_location: idOrigin,
                ss_address: address,
                landline_number: homePhone,
                phone_number: mobilePhone,
                role: memberType,
                specialization: selectedSpecializations,
                languages: selectedLanguagePair.map((obj) => {
                    return {
                        language_id_from: obj.language_from.value,
                        language_id_to: obj.language_to.value,
                    }
                }),
                company_name_location: agencyNamePlace,
                type,
                is_public: memberType === 'MUDA' ? false : profileAgreement,
                birth_date: new moment(birthDate).format('YYYY-MM-DD'),
                profile_picture_url: profilePic,
                // file_reference_letter_interpreter: interpreterFullMemberReq,
                resume: shortProfile,
                join_reason: joinReason,
                citizenship: citizenship,
                address_region: province,
                address_locality: city,

            }
            let fileRegistInterpreter = interpreterFullMemberReq?.map((v) => {
                return v.location
            });
            let fileRegistTranslator = translatorFullMemberReq?.map((v) => {
                return v.location
            });;
            if (memberType === 'PENUH') {
                switch (profession) {
                    case "Translator":
                        if (translatorFullMemberReq?.length === 0) {
                            Swal.fire('', 'Harap unggah pemenuhan Syarat Penerjemah','error')
                            setLoading(false)
                            return
                        }
                        payload.file_registration_requirements_type = fileRegReqType
                        payload.file_registration_requirements = fileRegistTranslator
                        break;
                    case "Interpreter":
                        if (interpreterFullMemberReq?.length === 0) {

                            Swal.fire('', 'Harap unggah pemenuhan syarat Juru Bahasa', 'error')
                            setLoading(false)
                            return
                        }
                        payload.file_reference_letter_interpreter = fileRegistInterpreter
                        break
                    default:
                        if (translatorFullMemberReq?.length === 0) {
                            Swal.fire('', 'Harap unggah pemenuhan Syarat Penerjemah', 'error')
                            setLoading(false)
                            return
                        }

                        if (interpreterFullMemberReq?.length === 0) {

                            Swal.fire('', 'Harap unggah pemenuhan syarat Juru Bahasa', 'error')
                            setLoading(false)
                            return
                        }

                        payload.file_registration_requirements_type = fileRegReqType
                        payload.file_registration_requirements = [...fileRegistTranslator]
                        payload.file_reference_letter_interpreter = [...fileRegistInterpreter]
                }
            }

            // if (translatorFullMemberReqLabel === translatorFullMemberReqOptions[0].label) {
            //     payload = { ...payload, file_book_refname: translatorFullMemberReq }
            // } else if (translatorFullMemberReqLabel === translatorFullMemberReqOptions[1].label) {
            //     payload = { ...payload, file_translation_sample: translatorFullMemberReq }
            // } else if (translatorFullMemberReqLabel === translatorFullMemberReqOptions[2].label) {
            //     payload = { ...payload, file_government_decision: translatorFullMemberReq }
            // } else if (translatorFullMemberReqLabel === translatorFullMemberReqOptions[3].label) {
            //     payload = { ...payload, file_employment_certificate: translatorFullMemberReq }
            // } else if (translatorFullMemberReqLabel === translatorFullMemberReqOptions[4].label) {
            //     payload = { ...payload, file_reference_letter: translatorFullMemberReq }
            // }

            console.log('payload : ', payload)

            let result = await model.register(payload)

            console.log('hubla', result)

            Swal.fire({
                title: 'Informasi pendaftaran Saudara telah tersimpan',
                text: 'Mohon menunggu, pengajuan pendaftaran Saudara sedang kami proses',
                icon: 'info',
                confirmButtonText: 'Oke',
            }).then((result) => {
                history.push('/login')
            })

            setLoading(false)
        } catch (e) {
            console.log(e)
            await Swal.fire({
                title: 'Terjadi Kesalahan',
                text: e.error_message,
                icon: 'error',
                confirmButtonText: 'OKE',
            })
            setLoading(false)
        }


    }

    return (
        <Container>
            {/*<Row*/}
            {/*    style={{*/}
            {/*        fontFamily: 'Signika',*/}
            {/*        fontWeight: '600',*/}
            {/*        fontSize: '2em',*/}
            {/*        display: 'flex',*/}
            {/*        justifyContent: 'center',*/}
            {/*    }}*/}
            {/*>*/}
            {/*    <img*/}
            {/*        src={logoHPI}*/}
            {/*        style={{*/}
            {/*            width: 200,*/}
            {/*        }}*/}
            {/*        alt="sihapei"*/}
            {/*    />*/}
            {/*</Row>*/}
            <Row className="page-title">Daftar</Row>

            <Row style={{ marginTop: 20 }}>
                <Col xl={12} lg={12} md={12} xs={12} className={'m-auto'}>
                    {errorMsg ? <Alert type="error" className='mx-0' errorMsg={errorMsg}></Alert> : null}


                    <div className="mt-3 register-form-label">NAMA LENGKAP</div>
                    <InputGroup className={isFullNameFocused ? 'input-focus' : ''}>
                        <InputGroup.Prepend>
                            <InputGroup.Text className="input-group-prepend">
                                <MdPerson size={20} />
                            </InputGroup.Text>
                        </InputGroup.Prepend>

                        <FormControl
                            placeholder="Nama Lengkap"
                            className="form-control-custom"
                            onChange={(event) => {
                                setFullName(event.target.value)
                                setFullNameError((event.target.value).length === 0)
                            }}
                            onFocus={() => setFullNameFocused(true)}
                            onBlur={() => setFullNameFocused(false)}
                            value={fullName}
                        />
                    </InputGroup>
                    <div className="register-form-label mt-3">SUREL</div>
                    <InputGroup className={isEmailFocused ? 'input-focus' : ''}>
                        <InputGroup.Prepend>
                            <InputGroup.Text className="input-group-prepend">
                                <MdEmail size={20} />
                            </InputGroup.Text>
                        </InputGroup.Prepend>

                        <FormControl
                            placeholder="Surel"
                            className="form-control-custom"
                            onChange={(event) => {
                                setEmail(event.target.value)
                                setEmailError((event.target.value).length === 0)
                            }}
                            onFocus={() => setEmailFocused(true)}
                            onBlur={() => setEmailFocused(false)}
                            value={email}
                        />
                    </InputGroup>
                    <div className="mt-3 register-form-label">NOMOR TELEPON SELULER</div>
                    <InputGroup>
                        {/* <InputGroup.Prepend>
                            <InputGroup.Text className="input-group-prepend">
                                <MdPhoneIphone size={20} />
                            </InputGroup.Text>
                        </InputGroup.Prepend> */}
                         {/* <FormControl
                            placeholder="Sertakan kode negara dan kode wilayah"
                            className="form-control-custom"
                            autoComplete={"new-password"}
                            onChange={(event) => {
                                setMobilePhone(event.target.value)
                                setMobilePhoneError(event.target.value.length === 0)
                            }}
                        />  */}
                        <PhoneInput
                                placeholder="Phone Number"
                                className="form-control-custom"
                                countryCodeEditable={false}
                                autoFormat={false}
                                country={'id'}
                                preferredCountries={['id']}
                                preserveOrder={['preferredCountries']}
                                disableDropdown={false}
                                value={mobilePhone} onChange={(e) => setMobilePhone(e)}
                                    inputStyle={{
                                        borderWidth: "0",
                                        backgroundColor: "#e9f3f4",
                                        outline: "none",
                                        boxShadow: "none",
                                        fontFamily: "Signika",
                                        fontWeight: "600",
                                        
                                    }} 
                                buttonStyle={{backgroundColor: "lightGray", /* backgroundClip: 'border-box', borderRadius: 5 */}}/>
                    </InputGroup>
                             

                    <div className="register-form-label mt-3">KATA SANDI</div>
                    <InputGroup
                        style={{
                            backgroundColor: '#e9f3f4',
                            boxShadow: isPasswordFocused ? '0 0 10px #9ecaed' : 'none',
                            border: isPasswordFocused ? '1px solid  #9ecaed' : 'none',
                            borderRadius: 5,
                        }}
                    >
                        <InputGroup.Prepend>
                            <InputGroup.Text className="input-group-prepend">
                                <MdLock size={20} />
                            </InputGroup.Text>
                        </InputGroup.Prepend>

                        <FormControl
                            autoComplete={"new-password"}
                            placeholder="Kata Sandi"
                            type={isPasswordVisible ? 'text' : 'password'}
                            className="form-control-custom"
                            onChange={(event) => {
                                setPassword(event.target.value)
                                setPasswordError((event.target.value).length === 0)
                            }}
                            onFocus={() => setPasswordFocused(true)}
                            onBlur={() => setPasswordFocused(false)}
                        />

                        <InputGroup.Prepend
                            style={{
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                paddingRight: 10,
                                marginRight: 10,
                                backgroundColor: '#e9f3f4',
                            }}
                            onClick={() => setPasswordVisible(!isPasswordVisible)}
                        >
                            {isPasswordVisible ? <IoMdEye size={20} /> : <IoMdEyeOff size={20} />}
                        </InputGroup.Prepend>
                    </InputGroup>

                    <div className="register-form-label mt-3">KONFIRMASI KATA SANDI</div>
                    <InputGroup
                        style={{
                            backgroundColor: '#e9f3f4',
                            boxShadow: isPasswordConfirmFocused ? '0 0 10px #9ecaed' : 'none',
                            border: isPasswordConfirmFocused ? '1px solid  #9ecaed' : 'none',
                            borderRadius: 5,
                        }}
                    >
                        <InputGroup.Prepend>
                            <InputGroup.Text className="input-group-prepend">
                                <MdLock size={20} />
                            </InputGroup.Text>
                        </InputGroup.Prepend>

                        <FormControl
                            placeholder="Konfirmasi Kata Sandi"
                            type={isPasswordConfirmVisible ? 'text' : 'password'}
                            className="form-control-custom"
                            onChange={(event) => {
                                setPasswordConfirm(event.target.value)
                                setPasswordConfirmError((event.target.value).length === 0)
                            }}
                            onFocus={() => setPasswordConfirmFocused(true)}
                            onBlur={() => setPasswordConfirmFocused(false)}
                        />

                        <InputGroup.Prepend
                            style={{
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                paddingRight: 10,
                                marginRight: 10,
                                backgroundColor: '#e9f3f4',
                            }}
                            onClick={() => setPasswordConfirmVisible(!isPasswordConfirmVisible)}
                        >
                            {isPasswordConfirmVisible ? (
                                <IoMdEye size={20} />
                            ) : (
                                <IoMdEyeOff size={20} />
                            )}
                        </InputGroup.Prepend>
                    </InputGroup>

                    <div className="register-form-label mt-3">JENIS KELAMIN</div>
                    <InputGroup
                        style={
                            isCityFocused
                                ? {
                                    boxShadow: '0 0 10px #9ecaed',
                                    border: '1px solid  #9ecaed',
                                    borderRadius: 5,
                                }
                                : {}
                        }
                    >
                        <div style={{ display: 'grid' }}>
                            <div>
                                <input name="gender" type="radio" value="M" onClick={handleInput}
                                    onChange={(event) => setGenderError(!!!event.target.value)} />{' '}
                                Laki-laki
                            </div>
                            <div>
                                <input name="gender" type="radio" value="F" onClick={handleInput}
                                    onChange={(event) => setGenderError(!!!event.target.value)} />{' '}
                                Perempuan
                            </div>
                        </div>
                    </InputGroup>

                    <div className="mt-3 register-form-label">TEMPAT LAHIR</div>
                    <InputGroup>
                        <FormControl
                            placeholder="Tempat Lahir"
                            className="form-control-custom"
                            onChange={(event) => {
                                setBirthPlace(event.target.value)
                                setBirthPlaceError((event.target.value).length === 0)
                            }}
                        />
                    </InputGroup>

                    <div className="mt-3 register-form-label">TANGGAL LAHIR</div>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text
                                style={{
                                    borderWidth: 0,
                                    display: 'flex',
                                    alignItems: 'center',
                                    backgroundColor: '#e9f3f4',
                                }}
                            >
                                <IoMdCalendar size={20} />
                            </InputGroup.Text>
                        </InputGroup.Prepend>

                        <div style={{ flex: 1 }}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <DatePicker
                                    disableFuture
                                    openTo="year"
                                    format="DD/MM/YYYY"
                                    views={["year", "month", "date"]}
                                    value={birthDate}
                                    onChange={setBirthDate}
                                    className='w-100'
                                    style={{
                                        background: '#e9f3f4'
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    </InputGroup>

                    <div className="register-form-label mt-3">KEWARGANEGARAAN</div>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text
                                style={{
                                    borderWidth: 0,
                                    display: 'flex',
                                    alignItems: 'center',
                                    backgroundColor: '#e9f3f4',
                                }}
                            >
                                <MdLocationOn size={20} />
                            </InputGroup.Text>
                        </InputGroup.Prepend>

                        <div style={{ flex: 1 }}>
                            <Select
                                placeholder={'Pilih Kewarganegaraan'}
                                options={CITIZENSHIP_OPTION}
                                onChange={(e) => {
                                    setCitizenship(e.value)
                                    if(e.label === 'WNA') {
                                        
                                        onProvinceChange('0')
                                        setProvince('Luar Negeri')
                                        setCity('Luar Negeri')
                                        
                                    } else {
                                        onProvinceChange('')
                                        setProvince('')

                                    }
                                    setCitizenError(e.value === null)
                                }}
                                styles={{
                                    option: (provided, state) => ({
                                        ...provided,
                                        cursor: 'pointer',
                                    }),
                                    control: (provided) => ({
                                        ...provided,
                                        borderWidth: 0,
                                        backgroundColor: '#e9f3f4',
                                        fontFamily: 'Signika',
                                        fontWeight: '600',
                                        color: '#8e8e8e',
                                    }),
                                }}
                                value={CITIZENSHIP_OPTION.find(c => citizenship === c.value)}
                                components={{
                                    DropdownIndicator,
                                    IndicatorSeparator: () => null,
                                }}
                            />
                        </div>
                    </InputGroup>

                    <div className="mt-3 register-form-label">NOMOR KTP/PASPOR</div>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text className="input-group-prepend">
                                <MdCardMembership size={20} />
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                            placeholder="Nomor KTP"
                            className="form-control-custom"
                            onChange={(event) => {
                                setIdNumber(event.target.value)
                                setIdNumberError(event.target.value === '')
                            }}
                        />
                    </InputGroup>

                    <div className="mt-3 register-form-label">TEMPAT PENERBITAN KTP/PASPOR</div>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text className="input-group-prepend">
                                <MdCardMembership size={20} />
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                            placeholder="Tempat KTP di terbitkan"
                            className="form-control-custom"
                            onChange={(event) => {
                                setIdOrigin(event.target.value)
                                setIdOriginError(event.target.value.length === 0)
                            }}
                        />
                    </InputGroup>

                    <div className="register-form-label mt-3">PROVINSI</div>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text
                                style={{
                                    borderWidth: 0,
                                    display: 'flex',
                                    alignItems: 'center',
                                    backgroundColor: '#e9f3f4',
                                }}
                            >
                                <MdLocationOn size={20} />
                            </InputGroup.Text>
                        </InputGroup.Prepend>

                        <div style={{ flex: 1 }}>
                            <Select
                                placeholder={'Pilih Provinsi'}
                                options={citizenship == 'Foreign' ? [{label: 'Luar Negeri', value: '0'}] : provinces}
                                onChange={(e) => {
                                    setProvince(e.label)
                                    setProvinceError(e.value === null)
                                    onProvinceChange(e.value);
                                    // console.log(province);
                                }}
                                styles={{
                                    option: (provided, state) => ({
                                        ...provided,
                                        cursor: 'pointer',
                                    }),
                                    control: (provided) => ({
                                        ...provided,
                                        borderWidth: 0,
                                        backgroundColor: '#e9f3f4',
                                        fontFamily: 'Signika',
                                        fontWeight: '600',
                                        color: '#8e8e8e',
                                    }),
                                }}
                                value={citizenship == 'Foreign' ? {label: 'Luar Negeri', value: '0'} : provinces.find(p => province === p.value)}
                                //defaultValue={{label: 'Luar Negeri', value: '0'}}
                                components={{
                                    DropdownIndicator,
                                    IndicatorSeparator: () => null,
                                }}
                            />
                        </div>
                    </InputGroup>

                    {
                        (cities && cities.length > 0) &&
                        <>
                            <div className="register-form-label mt-3">KOTA / KABUPATEN</div>
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text
                                        style={{
                                            borderWidth: 0,
                                            display: 'flex',
                                            alignItems: 'center',
                                            backgroundColor: '#e9f3f4',
                                        }}
                                    >
                                        <MdLocationOn size={20} />
                                    </InputGroup.Text>
                                </InputGroup.Prepend>


                                <div style={{ flex: 1 }}>
                                    <Select
                                        placeholder={'Pilih Kota/Kabupaten'}
                                        options={citizenship == 'Foreign' ? [{label: 'Luar Negeri', value: '0', province_id: '0'}] : cities}
                                        value={citizenship == 'Foreign' ? {label: 'Luar Negeri', value: '0', province_id: '0'} : cities.find(c => city === c.label) || null}
                                        //defaultValue={{label: 'Luar Negeri', value: '0', province_id: '0'}}
                                        onChange={(e) => {
                                            console.log(e)
                                            setCity(e.label)
                                            setCityError(e.value === null)
                                            // console.log(province);
                                        }}
                                        styles={{
                                            option: (provided, state) => ({
                                                ...provided,
                                                cursor: 'pointer',
                                            }),
                                            control: (provided) => ({
                                                ...provided,
                                                borderWidth: 0,
                                                backgroundColor: '#e9f3f4',
                                                fontFamily: 'Signika',
                                                fontWeight: '600',
                                                color: '#8e8e8e',
                                            }),
                                        }}
                                        components={{
                                            DropdownIndicator,
                                            IndicatorSeparator: () => null,
                                        }}
                                        disabled={citizenship == 'Foreign' ? true : false}
                                    />
                                </div>


                            </InputGroup>

                        </>
                    }



                    <div className="mt-3 register-form-label">Alamat Lengkap sesuai KTP/Paspor</div>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text className="input-group-prepend">
                                <MdHome size={20} />
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                            placeholder="Sama seperti yang tertera di KTP/Paspor"
                            className="form-control-custom"
                            onChange={(event) => {
                                setAddress(event.target.value)
                                setAddressError(event.target.value.length === 0)
                            }}
                        />
                    </InputGroup>

                    <div className="mt-3 register-form-label">
                        Alamat tempat tinggal saat ini (jika berbeda dengan KTP/Paspor)
                    </div>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text className="input-group-prepend">
                                <MdHome size={20} />
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                            placeholder="Ketik perincian secara lengkap"
                            className="form-control-custom"
                            onChange={(event) => {
                                setCurrentAddress(event.target.value)
                                // setCurrentAddressError(event.target.value.length === 0)
                            }}
                        />
                    </InputGroup>


                    <div className="mt-3 register-form-label">Nomor Telepon Kedua (opsional)</div>
                    <InputGroup>
                        {/* <InputGroup.Prepend>
                            <InputGroup.Text className="input-group-prepend">
                                <MdPhone size={20} />
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                            placeholder="Nomor Telepon Kedua (Opsional)"
                            className="form-control-custom"
                            onChange={(event) => {
                                setHomePhone(event.target.value)
                                // setHomePhoneError(event.target.value.length === 0)
                            }}
                        /> */}
                        <PhoneInput
                                placeholder="Phone Number"
                                className="form-control-custom"
                                countryCodeEditable={false}
                                autoFormat={false}
                                country={'id'}
                                preferredCountries={['id']}
                                preserveOrder={['preferredCountries']}
                                disableDropdown={false}
                                value={homePhone} onChange={(e) => setHomePhone(e)}
                                    inputStyle={{
                                        borderWidth: "0",
                                        backgroundColor: "#e9f3f4",
                                        outline: "none",
                                        boxShadow: "none",
                                        fontFamily: "Signika",
                                        fontWeight: "600",
                                        
                                    }} 
                                buttonStyle={{backgroundColor: "lightGray", /* backgroundClip: 'border-box', borderRadius: 5 */}}/>
                    </InputGroup>
                    {/*{homePhoneError &&*/}
                    {/*    <Alert type="error" className='mx-0' errorMsg={'Bagian ini harus diisi'}></Alert>}*/}

                    <div className="mt-3 register-form-label">Sertakan foto diri Saudara</div>

                    <div>
                        <CustomDropzone
                            width={"150px"}
                            height={"150px"}
                            // id={"reference-component"}
                            aspect={1}
                            imageSrc={profilePic}
                            loading={isLoading}
                            prompt={<div style={{ fontFamily: "Signika" }}>Tambahkan foto profil <br />
                                (ukuran yang dianjurkan: 250 x 250)
                            </div>}
                            onDrop={acceptedFiles => onImagePicked(acceptedFiles)}
                        />
                    </div>

                    <div className="mt-3 register-form-label">
                        Pernyataan pilihan jenis keanggotaan (pilih salah satu)
                    </div>

                    <InputGroup
                        style={
                            isCityFocused
                                ? {
                                    boxShadow: '0 0 10px #9ecaed',
                                    border: '1px solid  #9ecaed',
                                    borderRadius: 5,
                                }
                                : {}
                        }
                    >
                        <div style={{ display: 'grid' }}>
                            <div>
                                <input
                                    name="memberType"
                                    type="radio"
                                    value="PENUH"
                                    onClick={handleInput}
                                />{' '}
                                Saya seorang penerjemah/juru bahasa profesional yang berpengalaman dan
                                ingin mendaftar sebagai Anggota Penuh Himpunan Penerjemah Indonesia.
                            </div>
                            <div>
                                <input
                                    name="memberType"
                                    type="radio"
                                    value="MUDA"
                                    onClick={handleInput}
                                />{' '}
                                Saya seorang pemula di bidang penerjemahan dan/atau penjurubahasaan, 
                                atau belum dapat memenuhi persyaratan untuk menjadi Anggota Penuh dan, 
                                oleh karena itu, ingin mendaftar sebagai Anggota Muda.
                            </div>
                        </div>
                    </InputGroup>

                    {/*
                    <div style={{ display: 'grid' }}>
                        <div>
                            <input
                                name="memberType"
                                type="radio"
                                value="PENUH"
                                onClick={() => {
                                    setMemberType('PENUH')
                                    setMemberTypeError(false)
                                }}
                            />{' '}
                            Saya seorang penerjemah/juru bahasa profesional yang berpengalaman dan
                            ingin mendaftar sebagai Anggota Penuh Himpunan Penerjemah Indonesia.
                        </div>
                        <div>
                            <input
                                name="memberType"
                                type="radio"
                                value="MUDA"
                                onClick={() => {
                                    setMemberType('MUDA')
                                    setMemberTypeError(false)

                                }}
                            />{' '}
                            Saya seorang pemula di bidang penerjemahan dan/atau penjurubahasaan,
                            atau belum dapat memenuhi persyaratan menjadi Anggota Penuh dan, oleh
                            karena itu, ingin mendaftar sebagai Anggota Muda.
                        </div>
                    </div> */}

                    <div
                        style={{
                            fontFamily: 'Signika',
                            fontWeight: '600',
                            fontSize: '0.75em',
                            color: '#8e8e8e',
                            marginBottom: 7,
                            marginTop: 20,
                        }}
                    >
                        PROFESI
                    </div>
                    <InputGroup
                        style={
                            isCityFocused
                                ? {
                                    boxShadow: '0 0 10px #9ecaed',
                                    border: '1px solid  #9ecaed',
                                    borderRadius: 5,
                                }
                                : {}
                        }
                    >
                        <div style={{ display: 'grid' }}>
                            <div>
                                <input
                                    name="profession"
                                    type="radio"
                                    value="Translator"
                                    onClick={handleInput}
                                />{' '}
                                Penerjemah
                            </div>
                            <div>
                                <input
                                    name="profession"
                                    type="radio"
                                    value="Interpreter"
                                    onClick={handleInput}
                                />{' '}
                                Juru Bahasa
                            </div>
                            <div>
                                <input
                                    name="profession"
                                    type="radio"
                                    value="Both"
                                    onClick={handleInput}
                                />{' '}
                                Keduanya
                            </div>
                        </div>
                    </InputGroup>
                    {
                        memberType === 'PENUH' && (profession === 'Translator' || profession === 'Both') ?
                            <>

                                <div className="register-form-label mt-3">
                                    SYARAT PENDAFTARAN SEBAGAI ANGGOTA PENUH (PENERJEMAH) - Pilih Satu
                                </div>
                                <InputGroup
                                    style={
                                        isCityFocused
                                            ? {
                                                boxShadow: '0 0 10px #9ecaed',
                                                border: '1px solid  #9ecaed',
                                                borderRadius: 5,
                                            }
                                            : {}
                                    }
                                >
                                    <InputGroup.Prepend>
                                        <InputGroup.Text
                                            style={{
                                                borderWidth: 0,
                                                display: 'flex',
                                                alignItems: 'center',
                                                backgroundColor: '#e9f3f4',
                                            }}
                                        >
                                            <MdHelp size={20} />
                                        </InputGroup.Text>
                                    </InputGroup.Prepend>

                                    <div style={{ flex: 1 }}>
                                        <Select
                                            onChange={(option) => {
                                                console.log('Province has been changed!')
                                                setTranslatorFullMemberReqLabel(option.label)
                                                setFileRegReqType(option?.value)
                                                setTranslatorFullMemberReqError(option.value.length === 0)
                                            }}
                                            placeholder={'Pilih syarat pendaftaran'}
                                            options={translatorFullMemberReqOptions}
                                            styles={{
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    cursor: 'pointer',
                                                }),
                                                control: (provided) => ({
                                                    ...provided,
                                                    borderWidth: 0,
                                                    backgroundColor: '#e9f3f4',
                                                    fontFamily: 'Signika',
                                                    fontWeight: '600',
                                                    color: '#8e8e8e',
                                                }),
                                            }}
                                            components={{
                                                DropdownIndicator,
                                                IndicatorSeparator: () => null,
                                            }}
                                            value={translatorFullMemberReqOptions.filter(
                                                (option) => option.label === translatorFullMemberReqLabel,
                                            )}
                                        />
                                    </div>
                                </InputGroup>

                                <div className="mt-3 register-form-label">
                                    Pemenuhan Syarat - {translatorFullMemberReqLabel}
                                </div>

                                <div>
                                    <label className="btn btn-sm btn-secondary" for="translatorReq">
                                        Unggah
                                    </label>

                                    <input
                                        style={{ display: 'none' }}
                                        id="translatorReq"
                                        type="file"
                                        onChange={handleUploadTranslatorReq}
                                    />
                                </div>

                                <Row>
                                    <Col md={12}>
                                        {translatorFullMemberReq &&
                                            translatorFullMemberReq?.map((values, key) => {
                                                return (
                                                    <>
                                                        <a href={values.location} alt='' target="_blank">
                                                            <Button variant='link'>
                                                                {values.name}
                                                            </Button>
                                                        </a>
                                                        <a className='text-secondary mr-4' onClick={(e) => {
                                                            let filtered = translatorFullMemberReq?.filter((v, key) => {
                                                                return v?.id !== values?.id
                                                            })
                                                            setTranslatorFullMemberReq(filtered)
                                                        }} href='javascript:;'>
                                                            <AiFillCloseCircle />
                                                        </a>
                                                    </>
                                                )
                                            })
                                        }
                                    </Col>
                                </Row>
                            </>
                            :
                            ''}

                    {
                        memberType === 'PENUH' && (profession === 'Interpreter' || profession === 'Both') ?
                            <>
                                <div className="mt-3 register-form-label">
                                SYARAT PENDAFTARAN SEBAGAI ANGGOTA PENUH (JURU BAHASA) - Surat rujukan/referensi dari 3 
                                (tiga) klien yang berbeda (dengan mencantumkan nama, alamat, dan nomor yang dapat dihubungi) 
                                yang menunjukkan bahwa Saudara telah melakukan pekerjaan yang memuaskan sebagai juru bahasa 
                                dengan total jam kerja minimal 50 (lima puluh) jam.
                                </div>

                                <label className="btn btn-sm btn-secondary" for="interpreterReq">
                                    Unggah
                                </label>

                                <input
                                    style={{ display: 'none' }}
                                    id="interpreterReq"
                                    type="file"
                                    onChange={handleUploadInterpreterReq}
                                />


                                <Row>
                                    <Col md={12}>
                                        {interpreterFullMemberReq &&
                                            interpreterFullMemberReq?.map((value, key) => {
                                                return (
                                                    <>
                                                        <a href={value?.location} alt='' target="_blank">
                                                            <Button variant='link'>
                                                                {value?.name}
                                                            </Button>
                                                        </a>

                                                        <a className='text-secondary mr-4' onClick={(e) => {
                                                            let filtered = interpreterFullMemberReq?.filter((v, key) => {
                                                                return v?.id !== value?.id
                                                            })
                                                            setInterpreterFullMemberReq(filtered)
                                                        }} href='javascript:;'>
                                                            <AiFillCloseCircle />
                                                        </a>
                                                    </>
                                                )
                                            })


                                        }
                                    </Col>
                                </Row>




                            </>
                            : ''}
                    <div className="mt-3 register-form-label">
                        Jika Saudara merupakan karyawan di sebuah Agensi/Perusahaan, sebutkan nama dan kota, provinsi, 
                        dan negara Agensi/Perusahaan tempat Saudara bekerja. 
                    </div>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text className="input-group-prepend">
                                <MdLocationCity size={20} />
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                            placeholder="Nama Perusahaan; Provinsi; Negara"
                            className="form-control-custom"
                            onChange={(event) => {
                                setAgencyNamePlace(event.target.value)
                                // setAgencyNamePlaceError(event.target.value.length === 0)
                            }}
                        />
                    </InputGroup>

                    <div
                        style={{
                            fontFamily: 'Signika',
                            fontWeight: '600',
                            fontSize: '0.75em',
                            color: '#8e8e8e',
                            marginBottom: 7,
                            marginTop: 20,
                        }}
                    >
                        Ketersediaan
                    </div>
                    <InputGroup
                        style={
                            isCityFocused
                                ? {
                                    boxShadow: '0 0 10px #9ecaed',
                                    border: '1px solid  #9ecaed',
                                    borderRadius: 5,
                                }
                                : {}
                        }
                    >
                        <div style={{ display: 'grid' }}>
                            <div>
                                <input
                                    name="availability"
                                    type="radio"
                                    value="full-time"
                                    onClick={handleInput}
                                />{' '}
                                Purnawaktu
                            </div>
                            <div>
                                <input
                                    name="availability"
                                    type="radio"
                                    value="part-time"
                                    onClick={handleInput}
                                />{' '}
                                Paruh waktu
                            </div>
                        </div>
                    </InputGroup>


                    <div
                        style={{
                            fontFamily: 'Signika',
                            fontWeight: '600',
                            fontSize: '0.75em',
                            color: '#8e8e8e',
                            marginBottom: 15,
                            marginTop: 20,
                        }}
                    >
                        PASANGAN BAHASA
                    </div>
                    <InputGroup
                        style={
                            isCityFocused
                                ? {
                                    boxShadow: '0 0 10px #9ecaed',
                                    border: '1px solid  #9ecaed',
                                    borderRadius: 5,
                                }
                                : {}
                        }
                    >
                        <Col className="px-0">
                            <Container fluid>
                                {selectedLanguagePair.map((obj, idx) => {
                                    return (
                                        <Row
                                            style={{
                                                marginBottom: 15,
                                            }}
                                        >
                                            <Col
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    margin: 0,
                                                    padding: 0,
                                                }}
                                                xs={5}
                                            >
                                                {obj.language_from.label}
                                            </Col>
                                            <Col
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}
                                                xs={1}
                                            >
                                                {'>'}
                                            </Col>
                                            <Col
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    margin: 0,
                                                    padding: 0,
                                                }}
                                                xs={5}
                                            >
                                                {obj.language_to.label}
                                            </Col>
                                            <Col
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    margin: 0,
                                                    padding: 0,
                                                    paddingLeft: 10,
                                                }}
                                                xs={1}
                                            >
                                                <IconButton
                                                    size={'small'}
                                                    onClick={() => {
                                                        let temp = [...selectedLanguagePair]
                                                        temp.splice(idx, 1)
                                                        setSelectedLanguagePair(temp)
                                                    }}
                                                    style={{
                                                        backgroundColor: Palette.PRIMARY,
                                                        borderColor: Palette.PRIMARY,
                                                    }}
                                                >
                                                    <FaMinus size={'12'} color={'white'} />
                                                </IconButton>
                                            </Col>
                                        </Row>
                                    )
                                })}
                                <Row>
                                    <Col
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            margin: 0,
                                            padding: 0,
                                        }}
                                        xs={5}
                                    >
                                        <Select
                                            onChange={(option) => {
                                                if (languageTo?.value === option.value) return

                                                setLanguageFrom(option)
                                            }}
                                            placeholder={'Dari'}
                                            value={languageFrom}
                                            options={languageSelection}
                                            styles={{
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    cursor: 'pointer',
                                                }),
                                                control: (provided) => ({
                                                    ...provided,
                                                    borderColor: '#e6e6e6',
                                                }),
                                                container: (provided) => ({
                                                    ...provided,
                                                    width: '100%',
                                                }),
                                            }}
                                            components={{
                                                DropdownIndicator,
                                                IndicatorSeparator: () => null,
                                            }}
                                        />
                                    </Col>
                                    <Col
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                        xs={1}
                                    >
                                        {'>'}
                                    </Col>
                                    <Col
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            margin: 0,
                                            padding: 0,
                                        }}
                                        xs={5}
                                    >
                                        <Select
                                            onChange={(option) => {
                                                if (languageFrom?.value === option.value) return

                                                setLanguageTo(option)
                                            }}
                                            placeholder={'Ke'}
                                            value={languageTo}
                                            options={languageSelection}
                                            styles={{
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    cursor: 'pointer',
                                                }),
                                                control: (provided) => ({
                                                    ...provided,
                                                    borderColor: '#e6e6e6',
                                                }),
                                                container: (provided) => ({
                                                    ...provided,
                                                    width: '100%',
                                                }),
                                            }}
                                            components={{
                                                DropdownIndicator,
                                                IndicatorSeparator: () => null,
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </InputGroup>

                    {memberType == 'PENUH' && (
                        <>
                            <div className="mt-3 register-form-label">
                                PERNYATAAN PEMAHAMAN DAN KEBENARAN INFORMASI
                            </div>

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="checkedB"
                                        color="primary"
                                        onChange={(event) => {
                                            setAgreementUnderstandingDeclaration(
                                                event.target.checked,
                                            )
                                            setAgreementUnderstandingDeclarationError(!event.target.checked)
                                        }
                                        }
                                    />
                                }
                                label="Saya memahami bahwa Himpunan Penerjemah Indonesia adalah organisasi profesi yang keanggotaannya bersifat perorangan, dan keterangan yang saya berikan di atas adalah keterangan tentang diri pribadi saya. Keterangan yang saya berikan adalah benar pada saat mengisi formulir ini."
                            />

                            <div className="mt-3 register-form-label">
                                PERNYATAAN KEBERSEDIAAN UNTUK MENAMPILKAN PROFIL DI DIREKTORI PENERJEMAH DAN JURU BAHASA
                                INDONESIA JIKA DITERIMA SEBAGAI ANGGOTA PENUH
                            </div>

                            <div style={{ display: 'grid' }}>
                                <div>
                                    <input
                                        name="profileAgreement"
                                        type="radio"
                                        value="true"
                                        onClick={() => {
                                            setProfileAgreement(true)
                                            setProfileAgreementError(false)
                                        }}
                                    />{' '}
                                    Saya bersedia
                                </div>
                                <div>
                                    <input
                                        name="profileAgreement"
                                        type="radio"
                                        value="false"
                                        onClick={() => {
                                            setProfileAgreement(false)
                                            setProfileAgreementError(false)
                                        }}
                                    />{' '}
                                    Saya tidak bersedia
                                </div>
                            </div>

                            <div className="mt-3 register-form-label">
                                PERNYATAAN KEPATUHAN TERHADAP PERATURAN ORGANISASI
                            </div>

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="checkedB"
                                        color="primary"
                                        onChange={(event) => {
                                            setComplianceAgreement(event.target.checked)
                                            setComplianceAgreementError(!event.target.checked)
                                        }
                                        }
                                    />
                                }
                                label="Apabila saya diterima sebagai Anggota Muda/Penuh Himpunan Penerjemah Indonesia (HPI), 
                                saya bersedia mematuhi semua aturan HPI, termasuk AD/ART dan Kode Etik dan Kode Perilaku Profesi Penerjemah dan Juru Bahasa, 
                                dan saya bersedia menerima sanksi apa pun yang dikenakan kepada diri saya apabila saya terbukti melanggar aturan tersebut, 
                                termasuk pembekuan dan/atau penghentian status keanggotaan saya di HPI, dan saya tidak akan mengajukan gugatan, tuntutan, 
                                dan/atau mengambil tindakan hukum apa pun terhadap Badan Pengurus HPI dan/atau anggota Badan Pengurus HPI secara perorangan 
                                dan/atau secara organisasi."
                            />


                            <div className="mt-3 register-form-label">
                                PERNYATAAN KETERIKATAN DENGAN SYARAT & KETENTUAN HPI YANG BERLAKU
                            </div>

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="checkedB"
                                        color="primary"
                                        onChange={(event) => {
                                            setBoundAgreement(event.target.checked)
                                            setBoundAgreementError(!event.target.checked)
                                        }
                                        }
                                    />
                                }
                                label="Dengan mengisi dan mengirim Formulir Pendaftaran ini kepada HPI, saya menyatakan diri saya terikat pada Syarat dan Ketentuan yang berlaku di HPI."
                            />

                        </>
                    )}
                    <div className="mt-3 register-form-label">
                        RIWAYAT HIDUP SINGKAT (Latar Belakang Pendidikan dan Pengalaman sebagai Penerjemah/Juru Bahasa)
                    </div>
                    <InputGroup>
                        <FormControl
                            as="textarea"
                            placeholder=""
                            className="form-control-custom"
                            onChange={(event) => {
                                setShortProfile(event.target.value)
                                setShortProfileError(event.target.value.length === 0)
                            }}
                        />
                    </InputGroup>

                    <div className="mt-3 register-form-label">
                        ALASAN SAYA INGIN BERGABUNG DENGAN HPI
                    </div>
                    <InputGroup>
                        <FormControl
                            as="textarea"
                            placeholder=""
                            className="form-control-custom"
                            onChange={(event) => {
                                setJoinReason(event.target.value)
                                setJoinReasonError(event.target.value.length === 0)
                            }}
                        />
                    </InputGroup>

                    <FormControlLabel
                        control={
                            <Checkbox
                                name="checkedB"
                                color="primary"
                                onChange={(event) => {
                                    setTnCAgreement(event.target.checked)
                                    setTnCAgreementError(!event.target.checked)
                                }
                                }
                            />
                        }
                        label={<>Saya menyatakan diri saya terikat pada Syarat dan Ketentuan yang berlaku di HPI.</>}
                    />

                    <Row
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: 25,
                            marginBottom: 20,
                        }}
                    >
                        <Button
                            style={{
                                fontFamily: 'Signika',
                                fontWeight: '600',
                                paddingTop: 10,
                                paddingBottom: 10,
                                backgroundColor: Palette.PRIMARY,
                                borderColor: Palette.PRIMARY,
                                opacity: isButtonHovered ? 0.9 : 1,
                            }}
                            className="w-100"
                            onMouseEnter={() => setButtonHovered(true)}
                            onMouseLeave={() => setButtonHovered(false)}
                            onClick={attemptRegister}
                            disabled={isLoading}
                        >
                            {isLoading ? (
                                <Spinner
                                    animation="border"
                                    size={'sm'}
                                    style={{
                                        color: 'white',
                                    }}
                                />
                            ) : (
                                'DAFTAR'
                            )}
                        </Button>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}

export default RegisterPage
