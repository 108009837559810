import Container from 'react-bootstrap/Container'
import React, { useEffect, useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import { FormControl, InputGroup, Modal } from 'react-bootstrap'
import {
    MdWork,
    MdRoomService,
    MdKeyboard,
    MdArchive,
    MdErrorOutline,
    MdEmail,
    MdAttachMoney,
    MdCreditCard,
} from 'react-icons/md'
import { } from 'react-icons/md'
import RichTextEditor from 'react-rte'
import Select, { components } from 'react-select'
import { IoMdArrowBack, IoMdCalendar } from 'react-icons/io'
import IconButton from '@material-ui/core/IconButton'
import { FaMinus, FaPlus, FaAngleDown, AiOutlineLink, FcAbout } from 'react-icons/all'

import Palette from '../Palette'
import Member from '../models/Member'

import Specialization from '../models/Specialization'
import Language from '../models/Language'
import Upload from '../models/Upload'
import { AiOutlineClose, FaDownload, FaTrash } from 'react-icons/all'
import Spinner from 'react-bootstrap/Spinner'
import EditProfileUploadModal from '../components/Modals/EditProfileUploadFileModal'
import Swal from 'sweetalert2'
import EditProfileAddMembershipModal from "../components/Modals/EditProfileAddMembershipModal";

function EditProfilePage(props) {
    const DropdownIndicator = (props) => {
        return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    <FaAngleDown color={'grey'} />
                </components.DropdownIndicator>
            )
        )
    }

    const fetchSelfData = async () => {
        let memberModel = new Member()

        try {
            let result = await memberModel.getMemberData()

            initializeData(result)
        } catch (e) {
            console.log('ERROR' + e)
        }
    }

    const getAllSpecialization = async () => {
        let specializationModel = new Specialization()
        try {
            const specializations = await specializationModel.getAllSpecialization()

            setSpecializationsOptions(
                specializations
                    .filter((spec) => !editSpecializationDesc.includes(spec))
                    .map((value) => ({ label: value.specialization, value: value.id })),
            )
        } catch (e) {
            console.log(e)
        }
    }

    const getAllLanguages = async () => {
        let languageModel = new Language()
        try {
            const languages = await languageModel.getAllLanguages()

            setLanguagesOptions(
                languages.map((obj) => {
                    return {
                        value: obj.id,
                        label: obj.language,
                    }
                }),
            )
            console.log(languages)
        } catch (e) {
            console.log(e)
        }
    }

    const handleUploadFile = (event) => {
        console.log(event)
        setEditFiles(event.target.files)
    }

    const handleBackButton = () => {
        props.history.goBack()
    }
    const [allowEdit, setAllowEdit] = useState(false)
    const [editResume, setEditResume] = useState('')
    const [editAvailability, setEditAvailability] = useState('')
    const [editOccupation, setEditOccupation] = useState('')
    const [editEducation, setEditEducation] = useState(() => RichTextEditor.createEmptyValue())
    const [editSpecializationDesc, setEditSpecializationDesc] = useState([])
    const [editCompanyNameLocation, setEditCompanyNameLocation] = useState(null)
    const [editLanguagesFrom, setEditLanguagesFrom] = useState(null)
    const [editLanguagesTo, setEditLanguagesTo] = useState(null)
    const [editServices, setEditServices] = useState(() => RichTextEditor.createEmptyValue())
    const [editTranslatorSince, setEditTranslatorSince] = useState('')
    const [editInterpreterSince, setEditInterpreterSince] = useState([])
    const [editPreferredCurrency, setEditPreferredCurrency] = useState('')
    const [editPaymentMethod, setEditPaymentMethod] = useState('')
    const [editSoftware, setEditSoftware] = useState('')
    const [editAboutMe, setEditAboutMe] = useState(() => RichTextEditor.createEmptyValue())
    const [isPublic, setIsPublic] = useState(true)
    const [editFiles, setEditFiles] = useState([])
    const [editRole, setEditRole] = useState('')


    const [isEditProfessionFocused, setEditProfessionFocused] = useState(false)
    const [editCompanyNameLocationFocused, setEditCompanyNameLocationFocused] = useState(null)
    const [editProfession, setEditProfession] = useState('')

    const initializeData = (profileData) => {
        // setEditResume(
        //     profileData.member?.resume
        //         ? RichTextEditor.createValueFromString(profileData.member.resume, 'html')
        //         : RichTextEditor.createEmptyValue(),
        // )
        setEditResume(
            profileData.member?.resume
                ? profileData.member?.resume
                : undefined,
        )
        setEditAvailability(
            profileData.member && profileData.member.availability
                ? profileData.member.availability
                : undefined,
        )
        setEditOccupation(
            profileData.member && profileData.member.occupation
                ? profileData.member.occupation
                : undefined,
        )
        setEditEducation(
            profileData.member && profileData.member.education
                ? RichTextEditor.createValueFromString(profileData.member.education, 'html')
                : RichTextEditor.createEmptyValue(),
        )
        setSelectedLanguagePair(
            profileData.languages.length
                ? profileData.languages.map((lang) => {
                    return {
                        language_from: { label: lang.language_from, value: lang.from_id },
                        language_to: { label: lang.language_to, value: lang.to_id },
                    }
                })
                : [],
        )
        setEditSpecializationDesc(
            profileData.specializations
                ? profileData.specializations.map((spec) => {
                    return {
                        label: spec.specialization,
                        value: spec.id,
                    }
                })
                : undefined,
        )
        setEditServices(
            profileData.member && profileData.member.service_offered
                ? RichTextEditor.createValueFromString(profileData.member.service_offered, 'html')
                : RichTextEditor.createEmptyValue(),
        )
        setEditTranslatorSince(
            profileData.member && profileData.member.translator_since
                ? profileData.member.translator_since
                : undefined,
        )
        setEditInterpreterSince(
            profileData.member && profileData.member.interpreter_since
                ? profileData.member.interpreter_since
                : undefined,
        )
        setEditPreferredCurrency(
            profileData.member && profileData.member.preferred_currency
                ? profileData.member.preferred_currency
                : undefined,
        )
        setEditPaymentMethod(
            profileData.member && profileData.member.payment_method
                ? profileData.member.payment_method
                : undefined,
        )
        setEditCompanyNameLocation(
            profileData.member && profileData.member.company_name_location
                ? profileData.member.company_name_location
                : undefined,
        )
        setEditSoftware(
            profileData.member && profileData.member.software
                ? profileData.member.software
                : undefined,
        )
        setEditAboutMe(
            profileData.member && profileData.member.about_me
                ? RichTextEditor.createValueFromString(profileData.member.about_me, 'html')
                : RichTextEditor.createEmptyValue(),
        )
        setIsPublic(
            profileData.member && profileData.member.is_public
                ? profileData.member.is_public
                : undefined,
        )
        setEditFiles(
            profileData.member && profileData.member.files && profileData.member.files.length > 0
                ? profileData.member.files
                : [],
        )
        setEditMembership(
            profileData.member && profileData.member.memberscol && profileData.member.memberscol.length > 0
                ? profileData.member.memberscol
                : [],
        )
        setEditProfession(profileData.member.profession ? profileData.member.profession : undefined)
        setEditRole(profileData.member.role ? profileData.member.role : undefined)

    }

    const [isEditAvailabilityFocused, setEditAvailabilityFocused] = useState(false)
    const [isEditOccupationFocused, setEditOccupationFocused] = useState(false)
    const [isEditSpecializationDescFocused, setEditSpecializationDescFocused] = useState(false)
    const [isEditLanguagesFocused, setEditLanguagesFocused] = useState(false)
    const [isEditTranslatorSinceFocused, setEditTranslatorSinceFocused] = useState(false)
    const [isEditInterpreterSinceFocused, setEditInterpreterSinceFocused] = useState(false)
    const [isEditPreferredCurrencyFocused, setEditPreferredCurrencyFocused] = useState(false)
    const [isEditPaymentMethodFocused, setEditPaymentMethodFocused] = useState(false)
    const [isEditSoftwareFocused, setEditSoftwareFocused] = useState(false)
    const [isPublicFocused, setPublicFocused] = useState(false)
    const [specializationsOptions, setSpecializationsOptions] = useState([])
    const [languagesOptions, setLanguagesOptions] = useState([])
    const [selectedLanguagePair, setSelectedLanguagePair] = useState([])

    const [isUploadingFile, setIsUploadingFile] = useState(false)

    const [isAddingMembership, setIsAddingMembership] = useState(false)
    const [editMembership, setEditMembership] = useState([])

    useEffect(() => {
        fetchSelfData()
        getAllLanguages()
        getAllSpecialization()
    }, [])

    let memberModel = new Member()

    const handleSave = async () => {
        let payload = {
            resume: editResume,
            availability: editAvailability,
            occupation: editOccupation,
            education: editEducation && editEducation.toString('html'),
            specialization: editSpecializationDesc.map((spec) => {
                return { label: spec.label, id: spec.value }
            }),
            languages: selectedLanguagePair.map((selectLang) => {
                return {
                    from_id: selectLang.language_from.value,
                    to_id: selectLang.language_to.value,
                }
            }),
            company_name_location: editCompanyNameLocation,
            service_offered: editServices && editServices.toString('html'),
            profession: editProfession,
            translator_since: editTranslatorSince,
            interpreter_since: editInterpreterSince,
            preferred_currency: editPreferredCurrency,
            payment_method: editPaymentMethod,
            software: editSoftware,
            about_me: editAboutMe && editAboutMe.toString('html'),
            is_public: isPublic,
            files: editFiles,
            memberscol: editMembership
        }

        console.log(payload)

        try {
            let result = await memberModel.editMemberData(payload)

            console.log(result)

            Swal.fire({
                title: 'Profil tersimpan',
                icon: 'success',
                confirmButtonText: 'OKE',
            })
        } catch (e) {
            Swal.fire({
                title: 'Terjadi kesalahan',
                icon: 'error',
                confirmButtonText: 'OKE',
            })
            console.log(e)
        }
    }
    return (
        <>
            <Container className="text-left">
                <Row>
                    <div
                        onClick={handleBackButton}
                        style={{
                            backgroundColor: 'white',
                            borderColor: 'white',
                            color: 'black',
                            cursor: 'pointer',
                        }}
                        className="p-3"
                    >
                        <IoMdArrowBack size={20} />
                    </div>
                    <div
                        style={{
                            fontFamily: 'Signika',
                            fontWeight: '800',
                            fontSize: '1.75em',
                            color: Palette.PRIMARY,
                        }}
                        className="text-center w-100"
                    >
                        Ubah Profil
                    </div>
                </Row>

                <Row style={{ marginTop: 20 }}>
                    <Col>

                        <div
                            style={{
                                fontFamily: 'Signika',
                                fontWeight: '600',
                                fontSize: '0.75em',
                                color: '#8e8e8e',
                                marginBottom: 7,
                            }}
                            className="mt-3 text-uppercase"
                        >
                            KEBERSEDIAAN UNTUK MENAMPILKAN PROFIL DI DIREKTORI PENERJEMAH DAN JURU BAHASA INDONESIA
                        </div>
                        <InputGroup
                            style={
                                isEditAvailabilityFocused
                                    ? {
                                        boxShadow: '0 0 10px #9ecaed',
                                        border: '1px solid  #9ecaed',
                                        borderRadius: 5,
                                    }
                                    : {}
                            }
                        >
                            <div style={{ display: 'grid' }}>
                                <div>
                                    <input
                                        name="is_public"
                                        type="radio"
                                        checked={isPublic}
                                        onClick={(event) => setIsPublic(true)}
                                    />{' '}
                                    Bersedia
                                </div>
                                <div>
                                    <input
                                        name="is_public"
                                        type="radio"
                                        checked={!isPublic}
                                        onClick={(event) => setIsPublic(false)}
                                    />{' '}
                                    Tidak Bersedia
                                </div>
                            </div>
                        </InputGroup>

                        <div
                            style={{
                                fontFamily: 'Signika',
                                fontWeight: '600',
                                fontSize: '0.75em',
                                color: '#8e8e8e',
                                marginBottom: 7,
                            }}
                            className="mt-3 text-uppercase"
                        >
                            Resume
                        </div>
                        {
                            allowEdit ? <InputGroup>
                                {/* <RichTextEditor
                                className="text-editor"
                                value={editResume}
                                onChange={setEditResume}
                            /> */}
                                <InputGroup.Prepend>
                                    <InputGroup.Text
                                        style={{
                                            borderWidth: 0,
                                            display: 'flex',
                                            alignItems: 'center',
                                            backgroundColor: '#e9f3f4',
                                        }}
                                    >
                                        <FcAbout size={20} />
                                    </InputGroup.Text>

                                    <InputGroup.Text
                                        style={{
                                            borderWidth: 0,
                                            display: 'flex',
                                            alignItems: 'center',
                                            paddingLeft: 0,
                                            paddingRight: 0,
                                        }}
                                    >
                                        <div
                                            style={{
                                                height: '75%',
                                                width: 2,
                                                backgroundColor: '#9ca4a6',
                                            }}
                                        />
                                    </InputGroup.Text>
                                </InputGroup.Prepend>

                                <FormControl
                                    placeholder={
                                        'Resume'

                                    }
                                    style={{
                                        borderWidth: 0,
                                        backgroundColor: '#e9f3f4',
                                        outline: 'none',
                                        boxShadow: 'none',
                                        fontFamily: 'Signika',
                                        fontWeight: '600',
                                    }}
                                    as="textarea"

                                    onChange={(event) => setEditResume(event.target.value)
                                    }
                                    value={editResume}
                                />
                            </InputGroup> : <div
                                dangerouslySetInnerHTML={{ __html: editResume }}
                            />
                        }


                        <div
                            style={{
                                fontFamily: 'Signika',
                                fontWeight: '600',
                                fontSize: '0.75em',
                                color: '#8e8e8e',
                                marginBottom: 7,
                            }}
                            className="mt-3 text-uppercase"
                        >
                            Ketersediaan *
                        </div>
                        <InputGroup
                            style={
                                isEditAvailabilityFocused
                                    ? {
                                        boxShadow: '0 0 10px #9ecaed',
                                        border: '1px solid  #9ecaed',
                                        borderRadius: 5,
                                    }
                                    : {}
                            }
                        >
                            <div style={{ display: 'grid' }}>
                                <div>
                                    <input
                                        name="availability"
                                        type="radio"
                                        value="full-time"
                                        checked={
                                            editAvailability &&
                                            editAvailability.toLowerCase() === 'full-time'
                                        }
                                        onClick={(event) => setEditAvailability(event.target.value)}
                                    />{' '}
                                    Purnawaktu
                                </div>
                                <div>
                                    <input
                                        name="availability"
                                        type="radio"
                                        value="part-time"
                                        checked={
                                            editAvailability &&
                                            editAvailability.toLowerCase() === 'part-time'
                                        }
                                        onClick={(event) => setEditAvailability(event.target.value)}
                                    />{' '}
                                    Paruh waktu
                                </div>
                            </div>
                        </InputGroup>

                        <div
                            style={{
                                fontFamily: 'Signika',
                                fontWeight: '600',
                                fontSize: '0.75em',
                                color: '#8e8e8e',
                                marginBottom: 7,
                            }}
                            className="mt-3 text-uppercase"
                        >
                            Status Pekerjaan *
                        </div>
                        <InputGroup
                            style={
                                isEditOccupationFocused
                                    ? {
                                        boxShadow: '0 0 10px #9ecaed',
                                        border: '1px solid  #9ecaed',
                                        borderRadius: 5,
                                    }
                                    : {}
                            }
                        >
                            <div style={{ display: 'grid' }}>
                                <div>
                                    <input
                                        name="occupation"
                                        type="radio"
                                        value="organization"
                                        checked={
                                            editOccupation &&
                                            editOccupation.toLowerCase() === 'organization'
                                        }
                                        onClick={(event) => setEditOccupation(event.target.value)}
                                    />{' '}
                                    Pegawai Perusahaan
                                </div>
                                <div>
                                    <input
                                        name="occupation"
                                        type="radio"
                                        value="freelance"
                                        checked={
                                            editOccupation &&
                                            editOccupation.toLowerCase() === 'freelance'
                                        }
                                        onClick={(event) => setEditOccupation(event.target.value)}
                                    />{' '}
                                    Pekerja Lepas
                                </div>
                            </div>
                        </InputGroup>

                        <div
                            style={{
                                fontFamily: 'Signika',
                                fontWeight: '600',
                                fontSize: '0.75em',
                                color: '#8e8e8e',
                                marginBottom: 7,
                            }}
                            className="mt-3 text-uppercase"
                        >
                            Pendidikan
                        </div>
                        <InputGroup style={{ minHeight: '250px', overflow: 'hidden' }}>
                            <RichTextEditor
                                className="text-editor"
                                value={editEducation}
                                onChange={setEditEducation}
                            />
                        </InputGroup>

                        <div
                            style={{
                                fontFamily: 'Signika',
                                fontWeight: '600',
                                fontSize: '0.75em',
                                color: '#8e8e8e',
                                marginBottom: 7,
                            }}
                            className="mt-3 text-uppercase"
                        >
                            Spesialisasi
                        </div>
                        <InputGroup
                            style={
                                isEditSpecializationDescFocused
                                    ? {
                                        boxShadow: '0 0 10px #9ecaed',
                                        border: '1px solid  #9ecaed',
                                        borderRadius: 5,
                                    }
                                    : {}
                            }
                        >
                            <Select
                                placeholder={"Pilih Spesialisasi"}
                                options={specializationsOptions}
                                value={editSpecializationDesc}
                                onChange={setEditSpecializationDesc}
                                className="w-100"
                                isMulti
                                styles={{
                                    // Fixes the overlapping problem of the component
                                    menu: (provided) => ({ ...provided, zIndex: 9999 }),
                                }}
                            />
                        </InputGroup>

                        <div
                            style={{
                                fontFamily: 'Signika',
                                fontWeight: '600',
                                fontSize: '0.75em',
                                color: '#8e8e8e',
                                marginBottom: 7,
                                marginTop: 20,
                            }}
                        >
                            PASANGAN BAHASA *
                        </div>
                        <InputGroup
                            style={
                                isEditLanguagesFocused
                                    ? {
                                        boxShadow: '0 0 10px #9ecaed',
                                        border: '1px solid  #9ecaed',
                                        borderRadius: 5,
                                    }
                                    : {}
                            }
                        >
                            <Col className="px-0">
                                <Container fluid>
                                    {selectedLanguagePair.map((obj, idx) => {
                                        return (
                                            <Row
                                                style={{
                                                    marginTop: 5,
                                                }}
                                            >
                                                <Col
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        margin: 0,
                                                        padding: 0,
                                                    }}
                                                    xs={5}
                                                >
                                                    {obj.language_from.label}
                                                </Col>
                                                <Col
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                    }}
                                                    xs={1}
                                                >
                                                    {'>'}
                                                </Col>
                                                <Col
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        margin: 0,
                                                        padding: 0,
                                                    }}
                                                    xs={5}
                                                >
                                                    {obj.language_to.label}
                                                </Col>
                                                <Col
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        margin: 0,
                                                        padding: 0,
                                                        paddingLeft: 10,
                                                    }}
                                                    xs={1}
                                                >
                                                    {
                                                        allowEdit ? <IconButton
                                                            size={'small'}
                                                            onClick={() => {
                                                                let temp = [...selectedLanguagePair]
                                                                temp.splice(idx, 1)
                                                                setSelectedLanguagePair(temp)
                                                            }}
                                                            style={{
                                                                backgroundColor: Palette.PRIMARY,
                                                                borderColor: Palette.PRIMARY,
                                                            }}
                                                        >
                                                            <FaMinus size={'12'} color={'white'} />
                                                        </IconButton> : ''
                                                    }

                                                </Col>
                                            </Row>
                                        )
                                    })}
                                    {
                                        allowEdit ? <Row
                                            style={{
                                                marginTop: 5,
                                            }}
                                        >
                                            <Col
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    margin: 0,
                                                    padding: 0,
                                                }}
                                                xs={5}
                                            >

                                                <Select
                                                    onChange={(option) => {
                                                        setEditLanguagesFrom(option)
                                                    }}
                                                    placeholder={'Dari'}
                                                    value={editLanguagesFrom}
                                                    options={languagesOptions}
                                                    styles={{
                                                        option: (provided, state) => ({
                                                            ...provided,
                                                            cursor: 'pointer',
                                                        }),
                                                        control: (provided) => ({
                                                            ...provided,
                                                            borderColor: '#e6e6e6',
                                                        }),
                                                        container: (provided) => ({
                                                            ...provided,
                                                            width: '100%',
                                                        }),
                                                        // Fixes the overlapping problem of the component
                                                        menu: (provided) => ({
                                                            ...provided,
                                                            zIndex: 9999,
                                                        }),
                                                    }}
                                                    components={{
                                                        DropdownIndicator,
                                                        IndicatorSeparator: () => null,
                                                    }}
                                                />
                                            </Col>
                                            <Col
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}
                                                xs={1}
                                            >
                                                {'>'}
                                            </Col>
                                            <Col
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    margin: 0,
                                                    padding: 0,
                                                }}
                                                xs={5}
                                            >
                                                <Select
                                                    onChange={(option) => {
                                                        setEditLanguagesTo(option)
                                                    }}
                                                    placeholder={'Ke'}
                                                    value={editLanguagesTo}
                                                    options={languagesOptions}
                                                    styles={{
                                                        option: (provided, state) => ({
                                                            ...provided,
                                                            cursor: 'pointer',
                                                        }),
                                                        control: (provided) => ({
                                                            ...provided,
                                                            borderColor: '#e6e6e6',
                                                        }),
                                                        container: (provided) => ({
                                                            ...provided,
                                                            width: '100%',
                                                        }),
                                                        menu: (provided) => ({
                                                            ...provided,
                                                            zIndex: 9999,
                                                        }),
                                                    }}
                                                    components={{
                                                        DropdownIndicator,
                                                        IndicatorSeparator: () => null,
                                                    }}
                                                />
                                            </Col>
                                            {
                                                allowEdit ? <Col
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        margin: 0,
                                                        padding: 0,
                                                        paddingLeft: 10,
                                                    }}
                                                    xs={1}
                                                >
                                                    <IconButton
                                                        disabled={
                                                            !editLanguagesFrom ||
                                                            !editLanguagesTo ||
                                                            editLanguagesFrom === editLanguagesTo
                                                        }
                                                        size={'small'}
                                                        onClick={() => {
                                                            setSelectedLanguagePair([
                                                                ...selectedLanguagePair,
                                                                {
                                                                    language_from: {
                                                                        label: editLanguagesFrom.label,
                                                                        value: editLanguagesFrom.value,
                                                                    },
                                                                    language_to: {
                                                                        label: editLanguagesTo.label,
                                                                        value: editLanguagesTo.value,
                                                                    },
                                                                },
                                                            ])
                                                            setEditLanguagesFrom(null)
                                                            setEditLanguagesTo(null)
                                                        }}
                                                        style={{
                                                            backgroundColor: Palette.PRIMARY,
                                                            borderColor: Palette.PRIMARY,
                                                        }}
                                                    >
                                                        <FaPlus size={'12'} color={'white'} />
                                                    </IconButton>
                                                </Col> : ''
                                            }

                                        </Row> : ''
                                    }

                                </Container>
                            </Col>
                        </InputGroup>

                        <div
                            style={{
                                fontFamily: 'Signika',
                                fontWeight: '600',
                                fontSize: '0.75em',
                                color: '#8e8e8e',
                                marginBottom: 7,
                            }}
                            className="mt-3 text-uppercase"
                        >
                            Layanan
                        </div>
                        <InputGroup style={{ minHeight: '250px', overflow: 'hidden' }}>
                            <RichTextEditor
                                className="text-editor"
                                value={editServices}
                                onChange={setEditServices}
                            />
                        </InputGroup>

                        <div
                            style={{
                                fontFamily: 'Signika',
                                fontWeight: '600',
                                fontSize: '0.75em',
                                color: '#8e8e8e',
                                marginBottom: 7,
                            }}
                            className="mt-3 text-uppercase"
                        >
                            Profesi *
                        </div>
                        {
                            allowEdit ? <InputGroup
                                style={
                                    isEditProfessionFocused
                                        ? {
                                            boxShadow: '0 0 10px #9ecaed',
                                            border: '1px solid  #9ecaed',
                                            borderRadius: 5,
                                        }
                                        : {}
                                }
                            >
                                <div style={{ display: 'grid' }}>
                                    <div>
                                        <input
                                            name="profession"
                                            type="radio"
                                            value="translator"
                                            checked={
                                                editProfession &&
                                                editProfession.toLowerCase() === 'translator'
                                            }
                                            onClick={(event) => setEditProfession(event.target.value)}
                                        />{' '}
                                        Penerjemah
                                    </div>
                                    <div>
                                        <input
                                            name="profession"
                                            type="radio"
                                            value="Interpreter"
                                            checked={
                                                editProfession &&
                                                editProfession.toLowerCase() === 'interpreter'
                                            }
                                            onClick={(event) => setEditProfession(event.target.value)}
                                        />{' '}
                                        Juru Bahasa
                                    </div>
                                    <div>
                                        <input
                                            name="profession"
                                            type="radio"
                                            value="both"
                                            checked={
                                                editProfession &&
                                                editProfession.toLowerCase() === 'both'
                                            }
                                            onClick={(event) => setEditProfession(event.target.value)}
                                        />{' '}
                                        Keduanya
                                    </div>
                                </div>
                            </InputGroup> : editProfession === 'translator' ? 'Penerjemah' : editProfession === 'interpreter' ? 'Juru Bahasa' : 'Keduanya'
                        }


                        {(editProfession === 'Both' ||
                            editProfession === 'Translator' ||
                            editProfession === 'both' ||
                            editProfession === 'translator') && (
                                <>
                                    <div
                                        style={{
                                            fontFamily: 'Signika',
                                            fontWeight: '600',
                                            fontSize: '0.75em',
                                            color: '#8e8e8e',
                                            marginBottom: 7,
                                        }}
                                        className="mt-3 text-uppercase"
                                    >
                                        Penerjemah sejak
                                    </div>
                                    <InputGroup
                                        style={
                                            isEditTranslatorSinceFocused
                                                ? {
                                                    boxShadow: '0 0 10px #9ecaed',
                                                    border: '1px solid  #9ecaed',
                                                    borderRadius: 5,
                                                }
                                                : {}
                                        }
                                    >
                                        <InputGroup.Prepend>
                                            <InputGroup.Text
                                                style={{
                                                    borderWidth: 0,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    backgroundColor: '#e9f3f4',
                                                }}
                                            >
                                                <IoMdCalendar size={20} />
                                            </InputGroup.Text>

                                            <InputGroup.Text
                                                style={{
                                                    borderWidth: 0,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    paddingLeft: 0,
                                                    paddingRight: 0,
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        height: '75%',
                                                        width: 2,
                                                        backgroundColor: '#9ca4a6',
                                                    }}
                                                />
                                            </InputGroup.Text>
                                        </InputGroup.Prepend>

                                        <FormControl
                                            placeholder={
                                                editTranslatorSince === undefined
                                                    ? 'Fill Translator Since'
                                                    : editTranslatorSince
                                            }
                                            style={{
                                                borderWidth: 0,
                                                backgroundColor: '#e9f3f4',
                                                outline: 'none',
                                                boxShadow: 'none',
                                                fontFamily: 'Signika',
                                                fontWeight: '600',
                                            }}
                                            type="month"
                                            onChange={(event) =>
                                                setEditTranslatorSince(event.target.value)
                                            }
                                            onFocus={() => setEditTranslatorSinceFocused(true)}
                                            onBlur={() => setEditTranslatorSinceFocused(false)}
                                            value={editTranslatorSince}
                                        />
                                    </InputGroup>
                                </>
                            )}

                        {(editProfession === 'Both' ||
                            editProfession === 'Interpreter' ||
                            editProfession === 'both' ||
                            editProfession === 'interpreter') && (
                                <>
                                    <div
                                        style={{
                                            fontFamily: 'Signika',
                                            fontWeight: '600',
                                            fontSize: '0.75em',
                                            color: '#8e8e8e',
                                            marginBottom: 7,
                                        }}
                                        className="mt-3 text-uppercase"
                                    >
                                        Juru bahasa sejak
                                    </div>
                                    <InputGroup
                                        style={
                                            isEditInterpreterSinceFocused
                                                ? {
                                                    boxShadow: '0 0 10px #9ecaed',
                                                    border: '1px solid  #9ecaed',
                                                    borderRadius: 5,
                                                }
                                                : {}
                                        }
                                    >
                                        <InputGroup.Prepend>
                                            <InputGroup.Text
                                                style={{
                                                    borderWidth: 0,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    backgroundColor: '#e9f3f4',
                                                }}
                                            >
                                                <IoMdCalendar size={20} />
                                            </InputGroup.Text>

                                            <InputGroup.Text
                                                style={{
                                                    borderWidth: 0,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    paddingLeft: 0,
                                                    paddingRight: 0,
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        height: '75%',
                                                        width: 2,
                                                        backgroundColor: '#9ca4a6',
                                                    }}
                                                />
                                            </InputGroup.Text>
                                        </InputGroup.Prepend>

                                        <FormControl
                                            placeholder={
                                                editInterpreterSince === undefined
                                                    ? 'Fill Interpreter Since'
                                                    : editInterpreterSince
                                            }
                                            style={{
                                                borderWidth: 0,
                                                backgroundColor: '#e9f3f4',
                                                outline: 'none',
                                                boxShadow: 'none',
                                                fontFamily: 'Signika',
                                                fontWeight: '600',
                                            }}
                                            onChange={(event) =>
                                                setEditInterpreterSince(event.target.value)
                                            }
                                            onFocus={() => setEditInterpreterSinceFocused(true)}
                                            onBlur={() => setEditInterpreterSinceFocused(false)}
                                            type="month"
                                            value={editInterpreterSince}
                                        />
                                    </InputGroup>
                                </>
                            )}

                        <div
                            style={{
                                fontFamily: 'Signika',
                                fontWeight: '600',
                                fontSize: '0.75em',
                                color: '#8e8e8e',
                                marginBottom: 7,
                            }}
                            className="mt-3 text-uppercase"
                        >
                            Preferensi Mata Uang
                        </div>
                        <InputGroup
                            style={
                                isEditPreferredCurrencyFocused
                                    ? {
                                        boxShadow: '0 0 10px #9ecaed',
                                        border: '1px solid  #9ecaed',
                                        borderRadius: 5,
                                    }
                                    : {}
                            }
                        >
                            <InputGroup.Prepend>
                                <InputGroup.Text
                                    style={{
                                        borderWidth: 0,
                                        display: 'flex',
                                        alignItems: 'center',
                                        backgroundColor: '#e9f3f4',
                                    }}
                                >
                                    <MdAttachMoney size={20} />
                                </InputGroup.Text>

                                <InputGroup.Text
                                    style={{
                                        borderWidth: 0,
                                        display: 'flex',
                                        alignItems: 'center',
                                        paddingLeft: 0,
                                        paddingRight: 0,
                                    }}
                                >
                                    <div
                                        style={{
                                            height: '75%',
                                            width: 2,
                                            backgroundColor: '#9ca4a6',
                                        }}
                                    />
                                </InputGroup.Text>
                            </InputGroup.Prepend>

                            <FormControl
                                placeholder={
                                    editPreferredCurrency === undefined
                                        ? 'Isi info mata uang'
                                        : editPreferredCurrency
                                }
                                style={{
                                    borderWidth: 0,
                                    backgroundColor: '#e9f3f4',
                                    outline: 'none',
                                    boxShadow: 'none',
                                    fontFamily: 'Signika',
                                    fontWeight: '600',
                                }}
                                onChange={(event) => setEditPreferredCurrency(event.target.value)}
                                onFocus={() => setEditPreferredCurrencyFocused(true)}
                                onBlur={() => setEditPreferredCurrencyFocused(false)}
                                value={editPreferredCurrency}
                            />
                        </InputGroup>

                        <div
                            style={{
                                fontFamily: 'Signika',
                                fontWeight: '600',
                                fontSize: '0.75em',
                                color: '#8e8e8e',
                                marginBottom: 7,
                            }}
                            className="mt-3 text-uppercase"
                        >
                            Metode pembayaran
                        </div>
                        <InputGroup
                            style={
                                isEditPaymentMethodFocused
                                    ? {
                                        boxShadow: '0 0 10px #9ecaed',
                                        border: '1px solid  #9ecaed',
                                        borderRadius: 5,
                                    }
                                    : {}
                            }
                        >
                            <InputGroup.Prepend>
                                <InputGroup.Text
                                    style={{
                                        borderWidth: 0,
                                        display: 'flex',
                                        alignItems: 'center',
                                        backgroundColor: '#e9f3f4',
                                    }}
                                >
                                    <MdCreditCard size={20} />
                                </InputGroup.Text>

                                <InputGroup.Text
                                    style={{
                                        borderWidth: 0,
                                        display: 'flex',
                                        alignItems: 'center',
                                        paddingLeft: 0,
                                        paddingRight: 0,
                                    }}
                                >
                                    <div
                                        style={{
                                            height: '75%',
                                            width: 2,
                                            backgroundColor: '#9ca4a6',
                                        }}
                                    />
                                </InputGroup.Text>
                            </InputGroup.Prepend>

                            <FormControl
                                placeholder={
                                    editPaymentMethod === undefined
                                        ? 'Isi info metode pembayaran'
                                        : editPaymentMethod
                                }
                                style={{
                                    borderWidth: 0,
                                    backgroundColor: '#e9f3f4',
                                    outline: 'none',
                                    boxShadow: 'none',
                                    fontFamily: 'Signika',
                                    fontWeight: '600',
                                }}
                                onChange={(event) => setEditPaymentMethod(event.target.value)}
                                onFocus={() => setEditPaymentMethodFocused(true)}
                                onBlur={() => setEditPaymentMethodFocused(false)}
                                value={editPaymentMethod}
                            />
                        </InputGroup>

                        <div
                            style={{
                                fontFamily: 'Signika',
                                fontWeight: '600',
                                fontSize: '0.75em',
                                color: '#8e8e8e',
                                marginBottom: 7,
                            }}
                            className="mt-3 text-uppercase"
                        >
                            Perangkat lunak
                        </div>
                        <InputGroup
                            style={
                                isEditSoftwareFocused
                                    ? {
                                        boxShadow: '0 0 10px #9ecaed',
                                        border: '1px solid  #9ecaed',
                                        borderRadius: 5,
                                    }
                                    : {}
                            }
                        >
                            <InputGroup.Prepend>
                                <InputGroup.Text
                                    style={{
                                        borderWidth: 0,
                                        display: 'flex',
                                        alignItems: 'center',
                                        backgroundColor: '#e9f3f4',
                                    }}
                                >
                                    <MdKeyboard size={20} />
                                </InputGroup.Text>

                                <InputGroup.Text
                                    style={{
                                        borderWidth: 0,
                                        display: 'flex',
                                        alignItems: 'center',
                                        paddingLeft: 0,
                                        paddingRight: 0,
                                    }}
                                >
                                    <div
                                        style={{
                                            height: '75%',
                                            width: 2,
                                            backgroundColor: '#9ca4a6',
                                        }}
                                    />
                                </InputGroup.Text>
                            </InputGroup.Prepend>

                            <FormControl
                                placeholder={
                                    editSoftware === undefined ? 'Isi info perangkat lunak' : editSoftware
                                }
                                style={{
                                    borderWidth: 0,
                                    backgroundColor: '#e9f3f4',
                                    outline: 'none',
                                    boxShadow: 'none',
                                    fontFamily: 'Signika',
                                    fontWeight: '600',
                                }}
                                onChange={(event) => setEditSoftware(event.target.value)}
                                onFocus={() => setEditSoftwareFocused(true)}
                                onBlur={() => setEditSoftwareFocused(false)}
                                value={editSoftware}
                            />
                        </InputGroup>

                        <div
                            style={{
                                fontFamily: 'Signika',
                                fontWeight: '600',
                                fontSize: '0.75em',
                                color: '#8e8e8e',
                                marginBottom: 7,
                            }}
                            className="mt-3 text-uppercase"
                        >
                            Jika Anda Karyawan di Agensi/Perusahaan, Sebutkan Nama dan Lokasi Agensi/Perusahaan Tempat Anda Bekerja
                        </div>
                        <InputGroup
                            style={
                                editCompanyNameLocationFocused
                                    ? {
                                        boxShadow: '0 0 10px #9ecaed',
                                        border: '1px solid  #9ecaed',
                                        borderRadius: 5,
                                    }
                                    : {}
                            }
                        >
                            <InputGroup.Prepend>
                                <InputGroup.Text
                                    style={{
                                        borderWidth: 0,
                                        display: 'flex',
                                        alignItems: 'center',
                                        backgroundColor: '#e9f3f4',
                                    }}
                                >
                                    <MdKeyboard size={20} />
                                </InputGroup.Text>

                                <InputGroup.Text
                                    style={{
                                        borderWidth: 0,
                                        display: 'flex',
                                        alignItems: 'center',
                                        paddingLeft: 0,
                                        paddingRight: 0,
                                    }}
                                >
                                    <div
                                        style={{
                                            height: '75%',
                                            width: 2,
                                            backgroundColor: '#9ca4a6',
                                        }}
                                    />
                                </InputGroup.Text>
                            </InputGroup.Prepend>

                            <FormControl
                                placeholder={
                                    editCompanyNameLocation === undefined ? 'Isi Nama dan Lokasi Perusahaan' : editCompanyNameLocation
                                }
                                style={{
                                    borderWidth: 0,
                                    backgroundColor: '#e9f3f4',
                                    outline: 'none',
                                    boxShadow: 'none',
                                    fontFamily: 'Signika',
                                    fontWeight: '600',
                                }}
                                onChange={(event) => setEditCompanyNameLocation(event.target.value)}
                                onFocus={() => setEditCompanyNameLocationFocused(true)}
                                onBlur={() => setEditCompanyNameLocationFocused(false)}
                                value={editCompanyNameLocation}
                            />
                        </InputGroup>

                        <div
                            style={{
                                fontFamily: 'Signika',
                                fontWeight: '600',
                                fontSize: '0.75em',
                                color: '#8e8e8e',
                                marginBottom: 7,
                            }}
                            className="mt-3 text-uppercase"
                        >
                            Tentang saya
                        </div>
                        <InputGroup style={{ minHeight: '250px', overflow: 'hidden' }}>
                            <RichTextEditor
                                className="text-editor"
                                value={editAboutMe}
                                onChange={setEditAboutMe}
                            />
                        </InputGroup>

                        {editRole !== 'MUDA' && <>
                            <div
                                style={{
                                    fontFamily: 'Signika',
                                    fontWeight: '600',
                                    fontSize: '0.75em',
                                    color: '#8e8e8e',
                                    marginBottom: 7,
                                }}
                                className="mt-3 text-uppercase"
                            >
                                Akun terbuka ?
                            </div>
                            <InputGroup
                                style={
                                    isPublicFocused
                                        ? {
                                            boxShadow: '0 0 10px #9ecaed',
                                            border: '1px solid  #9ecaed',
                                            borderRadius: 5,
                                        }
                                        : {}
                                }
                            >
                                <div style={{ display: 'grid' }}>
                                    <div>
                                        <input
                                            name="isPublic"
                                            type="radio"
                                            value="0"
                                            checked={!isPublic}
                                            onClick={(e) => setIsPublic(false)}
                                        />{' '}
                                        Tertutup
                                    </div>
                                    <div>
                                        <input
                                            name="isPublic"
                                            type="radio"
                                            value="1"
                                            checked={isPublic}
                                            onClick={(e) => setIsPublic(true)}
                                        />{' '}
                                        Terbuka
                                    </div>
                                </div>
                            </InputGroup>
                        </>
                        }

                        <div
                            style={{
                                fontFamily: 'Signika',
                                fontWeight: '600',
                                fontSize: '0.75em',
                                color: '#8e8e8e',
                                marginBottom: 7,
                            }}
                            className="mt-3 text-uppercase"
                        >
                            Anggota dari
                        </div>

                        <div>
                            {Array.isArray(editMembership)
                                ? editMembership.map((obj, idx) => {
                                    return (
                                        <>
                                            <div
                                                style={{
                                                    backgroundColor: Palette.PRIMARY,
                                                    borderRadius: 5,
                                                    display: 'inline-block',
                                                    minWidth: 100,
                                                    color: 'white',
                                                    textAlign: 'center',
                                                    paddingTop: 3,
                                                    paddingBottom: 3,
                                                    paddingRight: 5,
                                                    paddingLeft: 5,
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            flex: 1,
                                                        }}
                                                    >
                                                        {obj.organization}
                                                    </div>

                                                    <div
                                                        style={{
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                        }}
                                                    >
                                                        <a target="_blank" href={obj.url}>
                                                            <AiOutlineLink
                                                                style={{
                                                                    color: 'white',
                                                                    marginRight: 5,
                                                                    marginLeft: 10,
                                                                }}
                                                            />
                                                        </a>

                                                        <FaTrash
                                                            onClick={() => {
                                                                let temp = [...editMembership]
                                                                temp.splice(idx, 1)
                                                                setEditMembership(temp)
                                                            }}
                                                            style={{
                                                                cursor: 'pointer',
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            {/*<a href={obj.location}>*/}
                                            {/*    <Button*/}
                                            {/*        size={"sm"}*/}
                                            {/*        style={{*/}
                                            {/*            */}
                                            {/*            borderColor: Palette.PRIMARY,*/}
                                            {/*        }}*/}
                                            {/*    >*/}
                                            {/*        */}
                                            {/*    </Button>*/}
                                            {/*</a>*/}
                                        </>
                                    )
                                })
                                : ''}
                        </div>
                        <Button
                            size={'sm'}
                            style={{
                                backgroundColor: Palette.PRIMARY,
                                borderColor: Palette.PRIMARY,
                                marginTop: 10,
                            }}
                            onClick={() => setIsAddingMembership(true)}
                        >
                            + Tambahkan keanggotaan (di asosiasi lain)
                        </Button>


                        {
                            editFiles?.length > 0 ? (
                                <>
                                    <div
                                        style={{
                                            fontFamily: 'Signika',
                                            fontWeight: '600',
                                            fontSize: '0.75em',
                                            color: '#8e8e8e',
                                            marginBottom: 7,
                                        }}
                                        className="mt-3 text-uppercase"
                                    >
                                        Berkas
                                    </div>

                                    <div>
                                        {Array.isArray(editFiles)
                                            ? editFiles.map((obj, idx) => {
                                                return (
                                                    <>
                                                        <div
                                                            style={{
                                                                backgroundColor: Palette.PRIMARY,
                                                                borderRadius: 5,
                                                                display: 'inline-block',
                                                                minWidth: 100,
                                                                color: 'white',
                                                                textAlign: 'center',
                                                                paddingTop: 3,
                                                                paddingBottom: 3,
                                                                paddingRight: 5,
                                                                paddingLeft: 5,
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    flexDirection: 'row',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        flex: 1,
                                                                    }}
                                                                >
                                                                    {obj.name}
                                                                </div>

                                                                <div
                                                                    style={{
                                                                        alignItems: 'center',
                                                                        justifyContent: 'center',
                                                                    }}
                                                                >
                                                                    <a href={obj.location}>
                                                                        <FaDownload
                                                                            style={{
                                                                                color: 'white',
                                                                                marginRight: 5,
                                                                                marginLeft: 10,
                                                                            }}
                                                                        />
                                                                    </a>
                                                                    {
                                                                        allowEdit ? <FaTrash
                                                                            onClick={() => {
                                                                                let temp = [...editFiles]
                                                                                temp.splice(idx, 1)
                                                                                setEditFiles(temp)
                                                                            }}
                                                                            style={{
                                                                                cursor: 'pointer',
                                                                            }}
                                                                        /> : ''
                                                                    }

                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/*<a href={obj.location}>*/}
                                                        {/*    <Button*/}
                                                        {/*        size={"sm"}*/}
                                                        {/*        style={{*/}
                                                        {/*            */}
                                                        {/*            borderColor: Palette.PRIMARY,*/}
                                                        {/*        }}*/}
                                                        {/*    >*/}
                                                        {/*        */}
                                                        {/*    </Button>*/}
                                                        {/*</a>*/}
                                                    </>
                                                )
                                            })
                                            : ''}
                                    </div>
                                </>
                            )
                                : ''}

                        {
                            allowEdit ?
                                <Button
                                    size={'sm'}
                                    style={{
                                        backgroundColor: Palette.PRIMARY,
                                        borderColor: Palette.PRIMARY,
                                        marginTop: 10,
                                    }}
                                    onClick={() => setIsUploadingFile(true)}
                                >
                                    + Tambahkan berkas
                                </Button>
                                : ''}
                        {/*<InputGroup*/}
                        {/*    style={*/}
                        {/*        isEditFilesFocused*/}
                        {/*            ? {*/}
                        {/*                  boxShadow: '0 0 10px #9ecaed',*/}
                        {/*                  border: '1px solid  #9ecaed',*/}
                        {/*                  borderRadius: 5,*/}
                        {/*                  flexWrap: 'nowrap',*/}
                        {/*              }*/}
                        {/*            : {*/}
                        {/*                  flexWrap: 'nowrap',*/}
                        {/*              }*/}
                        {/*    }*/}
                        {/*>*/}
                        {/*    <InputGroup.Prepend>*/}
                        {/*        <InputGroup.Text*/}
                        {/*            style={{*/}
                        {/*                borderWidth: 0,*/}
                        {/*                alignItems: 'center',*/}
                        {/*                backgroundColor: '#e9f3f4',*/}
                        {/*            }}*/}
                        {/*        >*/}
                        {/*            <MdArchive size={20} />*/}
                        {/*        </InputGroup.Text>*/}

                        {/*        <InputGroup.Text*/}
                        {/*            style={{*/}
                        {/*                borderWidth: 0,*/}
                        {/*                paddingLeft: 0,*/}
                        {/*                paddingRight: 0,*/}
                        {/*            }}*/}
                        {/*        >*/}
                        {/*            <div*/}
                        {/*                style={{*/}
                        {/*                    width: 2,*/}
                        {/*                    backgroundColor: '#9ca4a6',*/}
                        {/*                }}*/}
                        {/*            />*/}
                        {/*        </InputGroup.Text>*/}
                        {/*    </InputGroup.Prepend>*/}

                        {/*    <FormControl*/}
                        {/*        placeholder={'Upload your files'}*/}
                        {/*        style={{*/}
                        {/*            borderWidth: 0,*/}
                        {/*            backgroundColor: '#e9f3f4',*/}
                        {/*            outline: 'none',*/}
                        {/*            boxShadow: 'none',*/}
                        {/*            fontFamily: 'Signika',*/}
                        {/*            fontWeight: '600',*/}
                        {/*        }}*/}
                        {/*        type="file"*/}
                        {/*        multiple={true}*/}
                        {/*        onChange={handleUploadFile}*/}
                        {/*        // onChange={(event) => setEditFiles(event.target.files)}*/}
                        {/*        onFocus={() => setEditFilesFocused(true)}*/}
                        {/*        onBlur={() => setEditFilesFocused(false)}*/}
                        {/*    />*/}
                        {/*</InputGroup>*/}

                        <Row
                            style={{
                                display: 'flex',
                                justifyResumeContent: 'center',
                                marginTop: 25,
                                marginBottom: 20,
                            }}
                        >
                            <Button
                                style={{
                                    fontFamily: 'Signika',
                                    fontWeight: '600',
                                    paddingTop: 10,
                                    paddingBottom: 10,
                                    backgroundColor: Palette.PRIMARY,
                                    borderColor: Palette.PRIMARY,
                                }}
                                className="w-100 text-uppercase"
                                onClick={handleSave}
                            >
                                Simpan
                            </Button>
                        </Row>
                    </Col>
                </Row>
            </Container>

            <EditProfileUploadModal
                close={() => setIsUploadingFile(false)}
                show={isUploadingFile}
                onSubmit={(result) => {
                    setEditFiles([...editFiles, result])
                }}
            />

            <EditProfileAddMembershipModal
                close={() => setIsAddingMembership(false)}
                show={isAddingMembership}
                onSubmit={(result) => {
                    setEditMembership([...editMembership, result])
                }}
            />

        </>
    )
}

export default EditProfilePage
