import apiConfig from "./apiConfig"

export default class ApiRequest {

    static set = async (endpoint, method, body, googleAuth) => {
        let headers = googleAuth ?
            {
                'Google-Auth': googleAuth
            } :
            {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem('user') ? 'Bearer ' + JSON.parse(localStorage.getItem('user')).token : null,
                'Google-Auth': googleAuth
            }

            console.log(`[API REQUEST] ${apiConfig.base_url + endpoint}`)

        let response = await fetch(apiConfig.base_url + endpoint, {
            method: method,
            headers,
            body: JSON.stringify(body)
        });

        if (response.ok) {
            return response.json()
        } else {
            let error = await response.json()

            console.log('error : ', error)

            if (error.msg === 'JWT_EXPIRED' || error.msg === 'NO_TOKEN_PROVIDED' || error.msg === 'INVALID_TOKEN' || error.msg === 'BAD_TOKEN_FORMAT' || error.msg === 'NO_SECRET_DEFINED' || error.error_message === 'JWT_MALFORMED' || error.error_message?.msg === 'JWT_MALFORMED' || error.error_message === 'JWT_EXPIRED' || error.code === "SUBSCRIPTION_EXPIRED") {
                // delete sessionStorage.token;
                // delete localStorage.token;
                // alert('Login timeout')
                window.location.reload();
                throw error;
            }

            throw error

        }
    }

    static setMultipart = async (endpoint, method, body) => {
        console.log('API ACCESS: ' + endpoint);

        let response = await fetch(apiConfig.base_url + endpoint, {
            method: method,
            headers: {
                Authorization: localStorage.getItem('user') ? 'Bearer ' + JSON.parse(localStorage.getItem('user')).token : null,
                'Access-Control-Allow-Origin': '*',
            },
            body: body
        });

        if (response.ok) {
            console.log('response : ', response)

            return response.json()
        } else {
            let error = await response.json()

            console.log('error : ', error)

            throw error

        }
    }
}
